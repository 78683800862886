import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter } from "react-router-dom"
import { IMG_URL } from "../../config" // Default configuration
import { Dropdown } from "react-bootstrap" // Bootstrap design
import { connect } from "react-redux"
import { setItem, showMessageNotification } from "../../utils/Functions" // utility function
import _ from "lodash"
import API from "../../api/Routes"
import { sidebaropen } from "../../store/Actions" // Commun function
import { callApi } from "../../api" // Used for api call
import { ADMIN_URL, AUTH_URL } from "../../config"

import { fileupload } from "../../store/Actions"

/******************* 
@Purpose : Used for header view
@Parameter : props
@Author : INIC
******************/
function Header(props) {
  const [userPhoto, setUserPhoto] = useState("")
  const [userName, setUserName] = useState("")
  const [openSideBar, setOpenSideBar] = useState(
    props.sidebar && props.sidebar.data,
  )

  useEffect(() => {
    setFavIcon("/assets/images/favicon.png")
  }, [])

  useEffect(() => {
    if (props.admindata && !_.isEmpty(props.admindata)) {
      if (props.admindata && Object.keys(props.admindata).length > 0) {
        let { firstName, lastName, photo } = props.admindata
        setUserName(firstName + " " + lastName)
        setUserPhoto(photo)
      } else {
        let { firstName, lastName, photo } = props.admindata

        setUserName(firstName + " " + lastName)
        setUserPhoto(photo)
      }
    }
  }, [props.admindata])

  const getFavEl = () => {
    return document.getElementById("favicon")
  }

  const setFavIcon = (img = "") => {
    const favicon = getFavEl() // Accessing favicon element
    if (favicon) {
      favicon.href = IMG_URL + img
    }
  }

  /******************* 
  @Purpose : Used for outer click
  @Parameter : callback
  @Author : INIC
  ******************/
  function useOuterClick(callback) {
    const innerSettingsRef = useRef()
    const callbackRef = useRef()
    useEffect(() => {
      callbackRef.current = callback
    })

    useEffect(() => {
      document.addEventListener("click", handleClick)
      return () => document.removeEventListener("click", handleClick)

      function handleClick(e) {
        if (
          innerSettingsRef.current &&
          callbackRef.current &&
          !innerSettingsRef.current.contains(e.target)
        ) {
          callbackRef.current(e)
        }
      }
    }, [])

    return innerSettingsRef
  }

  /******************* 
  @Purpose : Used for sidebar open
  @Parameter : {}
  @Author : INIC
  ******************/
  const SidebarOpen = () => {
    document.body.classList.add("sidebar-open")
    setOpenSideBar(!openSideBar)
    props.sidebaropen(!openSideBar)
  }

  /******************* 
  @Purpose : Used for user logout
  @Parameter : {}
  @Author : INIC
  ******************/
  const logoutT = () => {
    props.callApi(API.LOG_OUT, {}, "post", null, true, false, AUTH_URL)
    setItem("accessToken", "")
    localStorage.clear()
    showMessageNotification("Logged Out Successfully.", "success")
    props.history.push("/")
  }

  let imagePreview = null
  if (userPhoto !== "/" && userPhoto !== null) {
    imagePreview = (
      <img
        className="round mr-2"
        src={userPhoto}
        alt="/assets/images/user.png"
        height="40"
        width="40"
      />
    )
  } else {
    imagePreview = (
      <img
        className="round mr-2"
        src={"/assets/images/avatar-s-16.jpg"}
        alt="avatar"
        height="40"
        width="40"
      />
    )
  }
  return (
    <div id="container1" className="App">
      <div className="main-content-area">
        <header>
          <div className="navigation-bar">
            <nav className="navbar d-flex navbar-expand bd-navbar fixed-top bg-white">
              <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                <ul className="nav navbar-nav">
                  <li className="nav-item mobile-menu">
                    <a
                      href="#"
                      onClick={() => {
                        SidebarOpen()
                      }}
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                    >
                      <i className="bx bx-menu"></i>
                    </a>
                  </li>
                </ul>
                <ul className="horizontal-brand nav navbar-nav">
                  <li>
                    <Link to="">
                      <img
                        className="img-fluid"
                        src="assets/images/brand-logo.svg"
                        alt="branding logo"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <ul className="navbar-nav flex-row ml-md-auto d-md-flex">
                <li className="nav-item dropdown dropdown-language"></li>
                <li className="nav-item nav-search">
                  <div className="search-input">
                    <div className="search-box">
                      <div className="search-input-icon">
                        <i className="bx bx-search primary"></i>
                      </div>
                      <input
                        className="input"
                        type="text"
                        placeholder="Explore Search..."
                        tabIndex="-1"
                        data-search="template-search"
                      />
                      <div className="search-input-close">
                        <i className="bx bx-x"></i>
                      </div>
                    </div>
                    <ul className="search-list">
                      <li className="auto-suggestion align-items-center justify-content-between cursor-pointer current_item">
                        <a
                          href="#"
                          className="align-items-center justify-content-between w-100"
                        >
                          <div className="justify-content-start">
                            <span className="mr-75 bx bx-error-circle"></span>
                            <span>No results found.</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item user-dropdown dropdown">
                  <Dropdown className="custome-dropdown">
                    <Dropdown.Toggle
                      id="dropdown-basic1"
                      className="custome-btn"
                    >
                      <Link
                        className="nav-link dropdown-toggle dropdown-user-link"
                        to="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <div className="user-nav d-sm-flex d-none">
                          <span className="user-name text-capitalize">
                            {userName}
                          </span>
                        </div>
                        <span>{imagePreview}</span>
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
                          </svg>
                        </span>
                      </Link>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <span onClick={() => props.history.push("/profile")}>
                          <i className="bx bx-reset mr-2"></i>Edit Profile
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <span onClick={() => logoutT()}>
                          <i className="bx bx-log-out mr-2"></i>Log out
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </div>
    </div>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  theme: "",
  resize: state.admin.resize,
  sidebar: state.admin.sidebar,
  admindata: state.admin.adminData,
  editadminprofile: state.admin.editAdminProfileData,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, {
  callApi,
  sidebaropen,
  fileupload,
})(withRouter(Header))
