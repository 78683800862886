import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";
import { en } from "./translations";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";


import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { Auth0Provider } from "@auth0/auth0-react";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // default language to use
  resources: {
    en: {
      language: en, // 'common' is our custom namespace
    },
  },
});

/******************* 
@Purpose : This page is default rander page for our project
@Parameter : {}
@Author : INIC
******************/
ReactDOM.render(
  // <StrictMode>
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Auth0Provider
          domain="dev-o1iz225e.us.auth0.com"
          clientId="w5LsqzRXmOHDBKu4Bo0QlRN2IAQU3G5A"
          redirectUri={window.location.origin}
        >
          <App />
        </Auth0Provider>
      </PersistGate>
    </Provider>
  </I18nextProvider>
  /* </StrictMode> */
  ,
  document.getElementById("root")
);
serviceWorker.register();
