import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout/Layout"
import { callApi } from "../../api" // Used for api call
import { connect } from "react-redux"
import API from "../../api/Routes"
import { ADMIN_URL } from "../../config"

/******************* 
@Purpose : Used for dashboard view
@Parameter : {}
@Author : INIC
******************/
function Dashboard(props) {
  const [hotelCount, setHotelCount] = useState(0)
  const [fetching, setfetching] = useState(true)
  useEffect(() => {
    ;(async () => {
      await getHotelsCount()
    })()
  }, [])

  const getHotelsCount = async () => {
    const response = await props.callApi(
      API.HOTEL_COUNT_DASHBOARD,
      {},
      "get",
      null,
      true,
      false,
      ADMIN_URL,
    )
    if (response?.data) {
      setHotelCount(response?.data?.totalHotel)
      setfetching(false)
    }
  }

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <nav aria-label="breadcrumb">
              <h3 className="navHeading">Dashboard</h3>
              <ol className="breadcrumb">
                <li className="content-header-title disabled">Navigation</li>
                <li className="content-header-title">Dashboard</li>
                <li className="breadcrumb-item">
                  <i className="bx bx-home-alt"></i>
                </li>
              </ol>
            </nav>
            <div className="comming-soon">
              {fetching ? (
                "Please Wait"
              ) : (
                <h2>
                  {hotelCount} {hotelCount > 1 ? "Hotels" : "Hotel"}
                </h2>
              )}
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2020 © Xenia</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  )
}

/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(Dashboard)
