import React from "react"
import { Link } from "react-router-dom"
import { getItem } from "../../utils/Functions"
/******************* 
@Purpose : Used for page not found view
@Parameter : {}
@Author : INIC
******************/
function FourZeroFour(props) {
  return (
    <div className="error-page">
      <div className="error-middle">
        <h2>Oops! Page not Found</h2>
        <h1>
          <span className="first-char">4</span>
          <span>0</span>
          <span className="last-char">4</span>
        </h1>
        <p>We can't find the page you're looking for.</p>
        <div className="back-home">
          {getItem("accessToken") ? (
            <Link
              className="btn btn-primary"
              onClick={() => props.history.push("/dashboard")}
            >
              Go to Home Page
            </Link>
          ) : (
            <Link
              className="btn btn-primary"
              onClick={() => props.history.push("/")}
            >
              Go to Login Page
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default FourZeroFour
