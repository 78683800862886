import React, { useEffect, useState, useRef } from "react"
import Layout from "../../components/Layout/Layout"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { callApi } from "../../api" // Used for api call
import { Link } from "react-router-dom"
import API from "../../api/Routes"
import _ from "lodash"
import StaticEditorEdit from "../../components/Editor/BlogEditorAdd"
import { ADMIN_URL } from "../../config"
import { ckEditorConfig, CustomStrings } from "../../utils/CustomText"
import { setFavIcon, showMessageNotification } from "../../utils/Functions"
import errorMessages from "../../utils/ErrorMessages" // Error Messages
// import { CKEditor } from "@ckeditor/ckeditor5-react"
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CKEditor from "react-ckeditor-component"
/******************* 
@Purpose : Used for static CMS pages
@Parameter : props
@Author : INIC
******************/
function AddCms(props) {
  const [lang] = useTranslation("language")
  const editorRef = useRef()
  const [gjsHtml, setGHtml] = useState("")
  const [pageTitle, setPageTitle] = useState("")
  let [errors, setErrors] = useState({})
  const pageTitleTrackChanges = useRef(null)
  let [loading, setLoading] = useState(false)

  /******************* 
  @Purpose : Used for page title change
  @Parameter : e
  @Author : INIC
  ******************/
  const handlePageTitleChange = (e) => {
    let maxChar = 70
    pageTitleTrackChanges.current.textContent = `Maximum ${
      maxChar - e.target.value.length
    } characters is suitable`
    setPageTitle(e.target.value.replace(/  +/g, " "))
    errors = Object.assign(errors, { pageTitle: "" })
    setErrors(errors)
  }

  /******************* 
  @Purpose : Used for preview
  @Parameter : content
  @Author : INIC
  ******************/
  const getpreview = (content) => {
    setGHtml(content)
  }

  /******************* 
  @Purpose : Used for handle add admin process
  @Parameter : {}
  @Author : INIC
  ******************/
  const addCMSHandler = async () => {
    try {
      const body = {
        title: pageTitle,
        content: gjsHtml,
      }
      if (validateForm() === true) {
        setLoading(true)
        const response = await props.callApi(
          API.CMS_ADD,
          body,
          "post",
          "",
          true,
          false,
          ADMIN_URL,
        )

        showMessageNotification(response?.extra_meta?.message + ".", "success")
        props.history.push("/cms-management")

        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  /******************* 
  @Purpose : Used for Validate Form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    var titleFormat = /^[a-zA-Z0-9 ]{5,70}$/
    let tempErrors = { pageTitle: "", gjsHtml: "" }
    let isFormValid = true

    //for page title
    if (!pageTitle) {
      tempErrors.pageTitle = errorMessages.PROVIDE_PAGE_TITLE
    } else if (!titleFormat.test(pageTitle)) {
      tempErrors.pageTitle = errorMessages.PROVIDE_VALID_PAGE_TITLE
    } else {
      tempErrors.pageTitle = ""
    }

    //for page title
    if (gjsHtml.length < 50) {
      tempErrors.gjsHtml = errorMessages.PROVIDE_AT_LEAST_FIFTY_CHAR
    } else {
      tempErrors.gjsHtml = ""
    }

    if (tempErrors.pageTitle !== "" || tempErrors.gjsHtml !== "") {
      isFormValid = false
    }

    setErrors(tempErrors)
    return isFormValid
  }

  useEffect(() => {
    setFavIcon(document.getElementById("favicon"))
  }, [])

  const onChangeCkEditor = (evt) => {
    var html = evt.editor.getData()
    setGHtml(html)
  }
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <nav aria-label="breadcrumb">
              <h3 className="navHeading">Manage CMS Pages</h3>
              <ol className="breadcrumb">
                <li className="content-header-title disabled">Navigation</li>
                <li className="content-header-title">
                  <span onClick={() => props.history.push("/cms-management")}>
                    CMS
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link onClick={() => props.history.push("/dashboard")}>
                    <i className="bx bx-home-alt"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add New CMS
                </li>
              </ol>
            </nav>
            <div className="d-flex align-items-center justify-content-end mb-2 flex-wrap">
              <span className="author d-lg-none d-flex">
                {lang("ContentManagement.CMS.Author")}
              </span>
            </div>
            <div className="blog-content-block">
              <div className="card card-media">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-12">
                    <div className="form-group mb-md-5 mb-3">
                      <label htmlFor="pageTitle">
                        Page Title<sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="pageTitle"
                        name="pageTitle"
                        placeholder="CMS Page Title"
                        value={pageTitle}
                        maxLength="70"
                        onChange={(e) => handlePageTitleChange(e)}
                      />
                      <small
                        id="pageTitle"
                        className="form-text"
                        ref={pageTitleTrackChanges}
                      >
                        Maximum {70 - pageTitle.length} characters is suitable
                      </small>
                      <span className="text-danger d-block">
                        {errors.pageTitle}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="blog-content">
                  <div className="img-drag-section">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-0">
                          <label htmlFor="caption">
                            {lang("ContentManagement.CMS.content")}
                            <sup className="text-danger">*</sup>
                          </label>

                          {/* <StaticEditorEdit
                            className="form-control mx-auto"
                            editContent={gjsHtml}
                            ref={editorRef}
                            getPreview={getpreview}
                            placeholder="CMS Page Content"
                          /> */}

                          {/* <CKEditor
                            editor={ClassicEditor}
                            data={gjsHtml}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor)
                            }}
                            onChange={(event, editor) => {
                              const edata = editor.getData()
                              setGHtml(edata)
                              console.log({ event, editor, edata })
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor)
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor)
                            }}
                          /> */}

                          <CKEditor
                            activeClass="p10"
                            content={gjsHtml}
                            config={ckEditorConfig}
                            events={{
                              // blur: this.onBlur,
                              afterPaste: (data) => {
                                onChangeCkEditor(data)
                              },
                              change: (data) => {
                                onChangeCkEditor(data)
                              },
                            }}
                          />
                          <span className="text-danger d-block">
                            {errors.gjsHtml}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-3">
                  <div className="form-group">
                    <div className="button-group-container">
                      {
                        <React.Fragment>
                          <button
                            onClick={addCMSHandler}
                            className="btn btn-primary mr-3 mb-sm-0 mb-2"
                          >
                            <span> {loading ? "Adding" : "Add"}</span>
                          </button>
                        </React.Fragment>
                      }
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          props.history.push("/cms-management")
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  // CmsPagesAccess: state.admin.adminData.staticRolePermission.cmsPagesAccess,
  CmsPagesAccess: { edit: true, delete: true, create: true },
  admindata: state.admin.adminData,
  language: state.admin.language,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(AddCms)
