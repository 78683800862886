import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { callApi } from "../../api" // Used for api call
import { connect } from "react-redux"
import errorMessages from "../../utils/ErrorMessages"
import API from "../../api/Routes"
import "react-datepicker/dist/react-datepicker.css"
import Layout from "../../components/Layout/Layout"
import {
  setFavIcon,
  showMessageNotification,
  validateHotelname,
} from "../../utils/Functions" //Utility functions
import { ADMIN_URL } from "../../config"
import { Formik, Form, Field, FieldArray } from "formik"
import * as Yup from "yup"

/******************* 
@Purpose : Used for user management (add user) page
@Parameter : props
@Author : INIC
******************/
function AddHotel(props) {
  const [lang] = useTranslation("language")
  let [loading, setLoading] = useState(false)
  const [hotelName, setHotelName] = useState("")

  Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
    return this.test("unique", message, function (list) {
      return list.length === new Set(list.map(mapper)).size
    })
  })

  useEffect(() => {
    setFavIcon(document.getElementById("favicon"))
  }, [])
  /******************* 
  @Purpose : Used for handle add admin process
  @Parameter : {}
  @Author : INIC
  ******************/
  const addHotelHandler = async (body) => {
    setLoading(true)
    try {
      const response = await props.callApi(
        API.HOTEL_ADD,
        body,
        "post",
        "",
        true,
        false,
        ADMIN_URL,
      )
      showMessageNotification(response?.extra_meta?.message + ".", "success")
      props.history.push("/hotel-management")
      setLoading(false)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  /******************* 
  @Purpose : Used for get post data
  @Parameter : {}
  @Author : INIC
  ******************/
  const getFormPostData = (values) => {
    const postData = {
      hotelName: values?.hotelName.replace(/  +/g, " "),
    }
    const emailList = values.hotelEmail.map((singleEmail) => {
      return singleEmail.email
    })
    postData.emailId = emailList
    addHotelHandler(postData)
  }

  return (
    <Layout>
      <Formik
        enableReinitialize={true}
        initialValues={{
          hotelName: "",
          hotelEmail: [""],
        }}
        validationSchema={Yup.object().shape({
          hotelName: Yup.string()
            .required(errorMessages.PROVIDE_HOTEL_NAME)
            .test(
              "Validate Hotel Name",
              errorMessages.PROVIDE_VALID_HOTEL_NAME +
                "(Max 30 without special character)",
              (value) => {
                if (value) {
                  return validateHotelname(value.replace(/  +/g, " "))
                }
              },
            ),
          hotelEmail: Yup.array()
            .of(
              Yup.object().shape({
                email: Yup.string()
                  .email(errorMessages.PROVIDE_VALID_EMAIL)
                  .required(errorMessages.PROVIDE_EMAIL)
                  .test(
                    "Validate Email",
                    errorMessages.PROVIDE_VALID_EMAIL,
                    (value) => {
                      const re =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      return re.test(String(value).toLowerCase())
                    },
                  ),
              }),
            )
            .unique(errorMessages.PROVIDE_UNIQUE_EMAIL_ID, (a) => a.email),
        })}
        validateOnBlur={true}
        validateOnChange={false}
        onSubmit={(values, { validate }) => {
          getFormPostData(values)
        }}
        render={({ values, errors, touched }) => (
          <Form>
            <div className="dashboard-container">
              <div className="main-content-area">
                <div className="overlay" />
                <div className="main-content-block">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <nav aria-label="breadcrumb">
                      <h3 className="navHeading">Manage Hotels</h3>
                      <ol className="breadcrumb">
                        <li className="content-header-title disabled">
                          Navigation
                        </li>
                        <li className="content-header-title">
                          <span
                            onClick={() => props.history.push("/hotel-management")}
                          >
                            Hotels
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                          <span
                            onClick={() => props.history.push("/dashboard")}
                          >
                            <i className="bx bx-home-alt"></i>
                          </span>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Add Hotel
                        </li>
                      </ol>
                    </nav>
                    <div className="btn-blocks mb-md-0 mb-2">
                      <button
                        type="submit"
                        className="btn btn-primary glow-primary mr-3"
                      >
                        <em className="bx bx-plus-circle mr-2" />
                        {loading ? "Adding" : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={() => props.history.push("/hotel-management")}
                        className="btn btn-default mr-3"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div className="card profile-card mb-5">
                    <div className="row">
                      <div className="col-md-8 mt-3">
                        <div className="user-title-info user-details">
                          <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                            <label className="mb-0" htmlFor="firstName">
                              {lang("Hotelmanagement.hotelname")}
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="w-100">
                              <Field name="hotelName">
                                {({ field, form, meta }) => (
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="hotelName"
                                    name="hotelName"
                                    placeholder={"Enter Hotel Name"}
                                    value={hotelName?.replace(/  +/g, " ")}
                                    {...field}
                                  />
                                )}
                              </Field>
                              {errors.hotelName ? (
                                <span className="text-danger d-block">
                                  {errors.hotelName}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <FieldArray
                      name="hotelEmail"
                      render={(arrayHelpers) => (
                        <>
                          {values.hotelEmail.map((hotel, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-8 mt-3">
                                <div className="user-title-info user-details">
                                  <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                    <label className="mb-0" htmlFor="hotelName">
                                      {lang(
                                        "Hotelmanagement.hotelsystemadmin",
                                      ) + "(s)"}
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="w-100">
                                      <Field
                                        name={`hotelEmail.${index}.email`}
                                        className="form-control"
                                        id="hotelEmail"
                                        placeholder={"Enter System Admin Email"}
                                      />
                                      <span className="text-danger d-block">
                                        {errors &&
                                          errors.hotelEmail &&
                                          errors.hotelEmail[index] &&
                                          errors.hotelEmail[index]?.email && (
                                            <span className="text-danger d-block">
                                              {errors.hotelEmail[index]?.email}
                                            </span>
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 mt-3">
                                {index > 0 && (
                                  <i
                                    className="bx bx-minus addnewBtn "
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                )}
                                {index === values.hotelEmail.length - 1 && (
                                  <i
                                    className="bx bx-plus addnewBtn ml-5"
                                    onClick={() => arrayHelpers.push("")}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    />

                    <div className="row">
                      <div className="col-md-8 mt-5">
                        <div className="user-title-info user-details">
                          <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                            <label className="mb-0" htmlFor="hotelName"></label>
                            <div className="w-100">
                              {errors && errors?.hotelEmail && (
                                <span className="text-danger d-block">
                                  {typeof errors?.hotelEmail === "string"
                                    ? errors?.hotelEmail
                                    : ""}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <footer>
                  <div className="footer-text d-flex align-items-centerf justify-content-between">
                    <span className="d-block">2020 © IndiaNIC</span>
                  </div>
                </footer>
              </div>
            </div>
          </Form>
        )}
      />
    </Layout>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(AddHotel)
