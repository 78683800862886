/******************* 
@Purpose : Used for english langauge default text
@Author : INIC
******************/

export const CustomStrings = {
  ApiResponse: {
    loginSuccess: "Login Successfully",
    emailSendSuccess: "Email sent successfully",
    resetPasswordSuccess: "Password updated successfully",
    profileUpdatedSuccess: "Profile Updated Successfully",
    imageUploadedSuccess: "Image Uploaded Successfully",
    hotelAddedSuccess: "Hotel added successfully",
    hotelDeletedSuccess: "Delete successfully",
    cmsDeletedSuccess: "CMS Detail Deleted successfully",
    emailTempDeletedSuccess: "Email Template Deleted successfully",
    cmsCreatedSuccess: "CMS Created Successfully",
    emailTempCreatedSuccess: "Email Template Created Successfully",
    emailTempUpdatedSuccess: "Email Template Updated Successfully",
    cmsUpdatedSuccess: "Updated Successfully",
    hotelUpdatedSuccess: "Updated successfully",
  },
  CustomLabels: {
    dashboard: "Dashboard",
    users: "Users",
    adminuser: "Admin Users",
    content: "Content",
    emailTemplateslabel: "Email Templates",
    master: "Master",
    roles: "Roles",
    Transcation: "Transcation",
    media: "Media",
    support: "Support",
    settings: "Settings",
    smtp: "SMTP/SMS Details",
    payment: "Payment Gateway SDK Details",
    staticpages: "Static Pages",
    blogs: "Blog Category",
    faq: "FAQs",
    generalsettings: "General Settings",
    socilamedia: "Social Media",
    SocialSdk: "Social Media SDK Details",
    SocialLinks: "Social Media Links",
    paymentGateway: "Payment Gateway SDK Details",
    emailNotification: "Email Notifications",
    Files: "Files",
  },
  EmailTemplates: {
    emailTemplates: "Email Templates",
    addNewTemplate: "Add New Template",
    templateTitle: "Template Title",
    title: "Title",
    subject: "Subject",
    fromemailAddress: "From email Address",
    tags: "Tags",
  },
  ContentManagement: {
    CMS: {
      edit: "Edit Blog",
      add: "Add Blog",
      content: "Content",
      category: "Category",
      blogTitle: "Blog Title",
      title: "Meta Title",
      slug: "slug",
      metaKeywords: "Meta Keywords",
      metaDescription: "Meta Description",
      blogDescription: "Blog Description",
      image: "Image",
      caption: "Caption",
      postDate: "Post Date",
      tag: "Tag",
      status: "Status",
      author: "Author",
    },
    CMSListing: {
      blog: "Blogs",
      addNewPage: "Add New Blog",
      articleName: "Article Name",
      category: "Category",
      created: "Created",
      status: "Status",
      action: "Action",
      noRecord: "No Records found!",
      search: "Search",
      addNewCMSPage: "Add New Page",
    },
  },
  Usermanagement: {
    edituser: {
      labels: {
        userprofile: "User Profile",
        firsname: "First Name",
        lastname: "Last Name",
        email: "Email",
        role: "Role",
        status: "Status",
        phoneNumber: "Phone Number",
        birthdate: "Birth date",
        website: "Website",
        gender: "Gender",
        address: "Address",
        country: "Country",
        company: "company",
      },
      buttons: {
        save: "Save",
        saving: "Saving",
        delete: "Delete",
      },
    },
  },
  FaviconIconUrl: "/assets/images/favicon.ico",
}

export const ckEditorConfig = {
  height:400,
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    { name: "forms", groups: ["forms"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
    },
    "/",
    { name: "links", groups: ["links"] },
    { name: "insert", groups: ["insert"] },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    "/",
    { name: "clipboard", groups: ["clipboard", "undo"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
  ],
  removeButtons:
    "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
  fontSize_sizes: "16/16px;24/24px;48/48px;",
  font_names:
    "Arial/Arial, Helvetica, sans-serif;" +
    "Times New Roman/Times New Roman, Times, serif;" +
    "Verdana",
  allowedContent: true,
}
