import React, { useState, useEffect, useRef, useCallback } from "react"
import Layout from "../../components/Layout/Layout"
import { connect } from "react-redux"
import "antd/dist/antd.css"
import { Select, Button } from "antd"
import "rc-pagination/assets/index.css"
import Pagination from "rc-pagination"
import { editUser } from "../../store/Actions"
import { callApi } from "../../api" // Used for api call
import swal from "sweetalert"
import _ from "lodash"
import { Link } from "react-router-dom"
import { ADMIN_URL, PUBLIC_FILE_URL } from "../../config"
import $ from "jquery"
import Loader from "../../components/Loader/Loader"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api/Routes"
import moment from "moment"
import { setFavIcon, showMessageNotification } from "../../utils/Functions" //Utility functions
import { useOnClickOutside, useSorting } from "../../hooks"
import { CustomStrings } from "../../utils/CustomText"
import ReactTooltip from "react-tooltip"

$.DataTable = require("datatables.net")
require("datatables.net-responsive")
var { Option } = Select
/******************* 
@Purpose : Used for users list view
@Parameter : props
@Author : INIC
******************/
function HotelsList(props) {
  const [hotelsList, setHotelsList] = useState([])
  const [pageSize, setPagesize] = useState(10)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [length, setLength] = useState(1)
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState("")
  const [sortData, sort, onSort] = useSorting()
  const [downloadpopup, openDownloadpopup] = useState(false)
  const refDownload = useRef()
  const onCloseDownload = useCallback(() => openDownloadpopup(false), [])
  useOnClickOutside(refDownload, onCloseDownload)

  useEffect(() => {
    setFavIcon(document.getElementById("favicon"))
  }, [])

  useEffect(() => {
    ;(async () => {
      await getAllHotels()
    })()
  }, [page, pageSize, sort])

  const getAllHotels = async () => {
    var body = {
      page,
      pageSize,
      sort,
      searchText: searchText,
    }
    const response = await props.callApi(
      API.HOTEL_LIST,
      body,
      "post",
      null,
      true,
      false,
      ADMIN_URL,
    )
    if (response.data) {
      var result = response.data.map(function (el) {
        var o = Object.assign({}, el)
        o.isChecked = false
        return o
      })

      setLoading(false)
      setHotelsList(result)
      setTotal(response?.meta?.total)
      setLength(response?.meta?.total)
    }
  }

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (page, pagesize) => {
    setPage(page)
    setPagesize(pagesize)
  }

  const numbers = [2, 5, 10, 15, 25, 50, 100, 200, 500].filter(
    (number) => number < total,
  )

  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField = async (keyword) => {
    var body = {
      pageSize,
      page,
      sort,
      searchText: keyword,
    }
    const response = await props.callApi(
      API.HOTEL_LIST,
      body,
      "post",
      null,
      true,
      false,
      ADMIN_URL,
    )
    if (response.data) {
      setHotelsList(response.data)
      setTotal(response?.meta?.total)
      setLength(response?.meta?.total)
      setLoading(false)

      // setUsersList(response.data.listing)
      // setSavedTemp(response.data.templateSettings)
      // setLatestColumnsArr(response.data.latestColumns)
      // setGetFilterValues(response.data.filterSettings)
      // setTotal(response.total)
      // setLength(response.data.listing.length)
      // setLoading(false)
    }
  }

  /******************* 
  @Purpose : Used for delete users
  @Parameter : uid
  @Author : INIC
  ******************/
  const deleteHotel = async (hotelid) => {
    if (hotelid) {
      swal({
        title: "Are you sure,You Want To Delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const response = await props.callApi(
            API.HOTEL_DELETE + hotelid,
            {},
            "delete",
            null,
            true,
            false,
            ADMIN_URL,
          )
          showMessageNotification(
            response?.extra_meta?.message + ".",
            "success",
          )
          setPage(1)
          setPagesize(10)
          getAllHotels()
        }
      })
    }
  }

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block overflow-auto">
            <nav aria-label="breadcrumb">
              <h3 className="navHeading">Manage Hotels</h3>
              <ol className="breadcrumb">
                <li className="content-header-title disabled">Navigation</li>
                <li className="content-header-title">Hotels</li>
                <li className="breadcrumb-item">
                  <span onClick={() => props.history.push("/dashboard")}>
                    <i className="bx bx-home-alt"></i>
                  </span>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Hotel List
                </li>
              </ol>
            </nav>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="content-area position-relative">
                  <div className="grid">
                    <div className="grid-content">
                      {/* <!-- filterbar buttons --> */}
                      <div className="user-listing-filterOptions mb-2 d-block">
                        <div className="row mb-2">
                          <div className="col-sm-8 position-static">
                            <div className="left-buttons d-flex ">
                              <div className="search position-relative has-icon-left">
                                <input
                                  type="search"
                                  className="form-control text-capitalize"
                                  placeholder="search"
                                  onChange={(evt) => {
                                    searchField(evt.target.value)
                                    setSearchText(evt.target.value)
                                  }}
                                />
                                <div className="form-control-position">
                                  <em className="bx bx-search"></em>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loading ? <Loader /> : null}
                          <div className="col-sm-4">
                            <div className="right-buttons d-flex justify-content-end">
                              <div
                                className={
                                  downloadpopup
                                    ? "custom-dropdown download-dropdown dropdown download-btn open"
                                    : "custom-dropdown download-dropdown dropdown download-btn"
                                }
                              >
                                <button
                                  id="addNewUser"
                                  type="button"
                                  className="btn glow-primary btn-primary mr-0 minW-md-0"
                                >
                                  <i className="bx bx-user-plus d-lg-none"></i>
                                  <span
                                    className="d-none d-sm-none d-lg-inline-block"
                                    onClick={() =>
                                      props.history.push("/add-hotel")
                                    }
                                  >
                                    <i className="bx bx-plus-circle"></i>{" "}
                                    &nbsp;&nbsp;Add New Hotel
                                  </span>
                                </button>

                                {/* <button
                              onClick={() => {
                                openDownloadpopup(!downloadpopup)
                                openFilterpopup(false)
                              }}
                              className="btn btn-default dropdown-toggle minW-md-0 btn-bg-white"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="bx bx-download d-lg-none"></i>
                              <span className="d-none d-sm-none d-lg-inline-block">
                                Download
                              </span>
                            </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    className="table row-border nowrap common-datatable"
                    id="user-listing"
                  >
                    <thead>
                      <tr>
                        <th onClick={() => onSort("name")}>
                          <b>Hotels</b>
                          <i
                            aria-hidden="true"
                            className={
                              sortData["name"]
                                ? "bx bxs-chevron-up"
                                : "bx bxs-chevron-down"
                            }
                          ></i>
                        </th>
                        <th onClick={() => onSort("createdAt")}>
                          <b>Created At</b>
                          <i
                            aria-hidden="true"
                            className={
                              sortData["createdAt"]
                                ? "bx bxs-chevron-up"
                                : "bx bxs-chevron-down"
                            }
                          ></i>
                        </th>

                        <th className="all">
                          <span>
                            <b>Action</b>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {hotelsList && hotelsList.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {!loading ? (
                              <h6
                                className="text-center text-danger not-found-txt mt-2"
                                colSpan="2"
                              >
                                No Records Found!
                              </h6>
                            ) : null}
                          </td>
                        </tr>
                      ) : (
                        hotelsList &&
                        Array.isArray(hotelsList) &&
                        hotelsList.map((hotel, key) => {
                          return (
                            <tr key={key}>
                              <td>{hotel.name}</td>
                              <td>
                                {moment(hotel.createdAt).format(
                                  "DD MMMM, YYYY HH:mm:ss",
                                )}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                  />
                                  <span
                                    className="cursor-pointer mr-3"
                                    data-tip={`Edit`}
                                    onClick={() =>
                                      props.history.push(
                                        `/detail-hotel/${hotel.id}`,
                                      )
                                    }
                                  >
                                    <i className="bx bx-edit" />
                                  </span>
                                  <span
                                    data-tip={`Delete`}
                                    onClick={() => deleteHotel(hotel.id)}
                                  >
                                    <i className="bx bx-trash-alt" />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                {hotelsList && hotelsList.length === 0 ? (
                  <div> </div>
                ) : (
                  <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center justify-content-between">
                    <div>
                      <label className="mr-2">Showing</label>
                      <Select
                        placeholder={<b>10 Items Per Page</b>}
                        optionFilterProp="children"
                        value={
                          hotelsList.length > 10 ? pageSize : hotelsList.length
                        }
                        onChange={(value) => {
                          setPage(1)
                          setPagesize(value)
                        }}
                      >
                        {numbers.map((number) => (
                          <Option value={number} key={number}>
                            {number}
                          </Option>
                        ))}
                        <Option value={total} key={total}>
                          {total}
                        </Option>
                      </Select>
                      <label className="ml-2">Out of {total} Hotels</label>
                    </div>
                    <div className="pagination-list mt-2 mt-sm-3">
                      <Pagination
                        className="ant-pagination"
                        pageSize={pageSize}
                        current={page}
                        total={total}
                        onChange={paginationChange}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2020 © IndiaNIC</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  UserAccess: {
    edit: true,
    delete: true,
    create: true,
    viewDetails: true,
    viewList: true,
  },
  // state.admin.adminData &&
  // state.admin.adminData.staticRolePermission &&
  // state.admin.adminData.staticRolePermission.userAccess,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi, editUser })(HotelsList)
