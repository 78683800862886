import React, { Fragment, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Window } from "@progress/kendo-react-dialogs";

import { connect } from "react-redux";
import { fileupload } from '../../store/Actions'
import Progressspinner from "../Layout/Progressspinner";

import API from "../../api/Routes";
import { callApi } from "../../api"; // Used for api call
import { ADMIN_URL } from "../../config"

const ModalOverlay = (props) => {
  const [windowStage, setWindowStage] = React.useState("MINIMIZED");
  const [files, setFiles] = React.useState([])

  useEffect(async () => {
    let fileus = []
    fileus = props.files
    if (props.fileproceed && props.fileproceed.key) {
      setWindowStage("DEFAULT")
      let fileproceed = props.fileproceed
      fileus.push(fileproceed)
      setFiles(oldFiles => [...oldFiles, fileproceed]);
      props.fileupload({ type: "FILE_UPLOAD", files: fileus })
      props.fileupload({ type: "FILE_UPLOAD_PROCEED", file: {} })
    }
  }, [props.fileproceed]);




  const handleStageChange = (e) => {
    setWindowStage(e.state);
  };

  const cancelfileupload = async (e) => {
    setFiles([])
    await props.fileupload({ type: "FILE_UPLOAD_CANCEL_ALL" })
  }

  return (
    <div>
      {props.files.length ?
        <Window
          title={"Uploading Status Bar"}
          stage={windowStage}
          onStageChange={handleStageChange}
          onClose={cancelfileupload}
        >
          <div>
            {files.length && <div>
              {files.map(file => {
                return <li className="parentli" key={file.key}><Progressspinner value={file.uploadPercentage ? file.uploadPercentage : 0} /> <span>{file.file?.fname}</span></li>
              })}
            </div>

            }
          </div>
        </Window> : <></>
      }

    </div>
  );
};


const FileModal = (props) => {
  const [uploadSpeed, setUploadSpeed] = React.useState(0)
  const [isOpenModel, setOpenModel] = React.useState(false)
  let fileRef = useRef(null);
  const [fileN, setFileName] = React.useState("")
  const [fileExt, setFileExt] = React.useState("")
  const [fileerror, setError] = React.useState("")

  const [windowStage, setWindowStage] = React.useState("DEFAULT");

  const handleStageChange = (e) => {
    setWindowStage(e.state);
  };


  useEffect(() => {
    if (props.files.newfile && props.files.newfile.name) {
      getFile()
    }
  }, [props.files.newfile]);

  useEffect(() => {
    if (props.files.openmodel) {
      setOpenModel(true)
    }
  }, [props.files.openmodel]);


  const uploadModel = async () => {
    if (fileerror) {
      return false
    } else {
      if (fileRef != null && fileRef.files && fileRef.files.length) {

        const file = fileRef.files[0];
        file["fname"] = fileN ? fileN + "." + fileExt : file.name
        setOpenModel(false)
        setFileExt("")
        setFileName("")
        setError("")
        fileRef = null
        await props.fileupload({ type: "CLOSE_MODEL" })
        await props.fileupload({ type: "NEW_FILE_UPLOAD_REQUEST", file: file })
      } else {
        setOpenModel(false)
        setFileExt("")
        setFileName("")
        setError("")
        setUploadSpeed(0)
        fileRef = null
        await props.fileupload({ type: "CLOSE_MODEL" })
      }
    }


  }

  const cancelModel = async () => {
    setOpenModel(false)
    setFileExt("")
    setFileName("")
    setError("")
    setUploadSpeed(0)
    fileRef = null
    await props.fileupload({ type: "CLOSE_MODEL" })
  }


  const checkfilestatus = async (fileName) => {
    let body = { fileName }
    const response = await props.callApi(
      API.FILE_STATUS,
      body,
      "post",
      "",
      false,
      false,
      ADMIN_URL
    );
    return response.data
  }

  const checkFileExist = async (fileName) => {
    let body = { fileName }
    const response = await props.callApi(
      API.FILE_STATUS_DB,
      body,
      "post",
      "",
      false,
      false,
      ADMIN_URL
    );
    return response.data
  }


  const selectImg = async (e) => {
    setError("")
    let file = e.target.files[0]
    let splittedF = file.name.split(".")
    let fileExt = splittedF[splittedF.length - 1]
    let filename = splittedF.slice(0, -1).join('.')
    setFileExt(fileExt)
    setFileName(filename)
    let files = await checkFileExist(filename + "." + fileExt)
    if (files && (files.dbstatus || files.serverstatus)) {
      setError("File name or file is already exist")
    } else {
      setError("")
    }
  }

  const getFile = async () => {
    if (props.files.newfile && props.files.newfile.name) {
      let filen = props.files.newfile.fname || props.files.newfile.name

      let checkedstatus = await checkfilestatus(filen)
      if (checkedstatus) {
        if (!checkedstatus.shouldUpload) {
          return true
        }

        let uploadedChunksSize = checkedstatus.uploadedChunksSize
        const chunks = await createFileChunks(props.files.newfile, 10, uploadedChunksSize);
        let spilittedext = filen.split(".");
        spilittedext.pop()
        spilittedext = spilittedext.join()

        let removingchunk = checkedstatus.uploadedChunks.length + chunks.length > 10 ? checkedstatus.uploadedChunks.pop() : checkedstatus.uploadedChunks
        const fileChunks = chunks.map((chunk, index) => ({
          chunk,
          chunkIndex: removingchunk.length + index,
          chunkSize: chunk.size
        }));

        let skip = 0
        let Mchunk = Math.ceil(props.files.newfile.size / 10);
        while (uploadedChunksSize > skip) {
          skip += Mchunk
        }

        if (skip > uploadedChunksSize) {
          uploadedChunksSize = skip - Mchunk
        }

        let obj = { id: props.files.files.length, key: spilittedext, chunks: fileChunks, file: props.files.newfile, isSuccess: false, isFail: false, url: "", uploadedChunks: removingchunk, uploadedChunksSize: uploadedChunksSize }
        await props.fileupload({ type: "NEW_FILE_UPLOAD_REQUEST", file: {} })
        await props.fileupload({ type: "FILE_UPLOAD_PROCEED", file: obj })
      }
    }
  }

  const createFileChunks = async (file, num, size = 0) => {
    const fileChunks = [];
    let skip = 0
    let Mchunk = Math.ceil(file.size / num);
    while (size > skip) {
      skip += Mchunk
    }
    if (skip > size) {
      skip = skip - Mchunk
    }

    const chunkSize = Math.ceil(file.size / num);

    while (skip < file.size) {
      fileChunks.push(file.slice(skip, skip + chunkSize));
      skip += chunkSize;
    }
    return fileChunks;
  }

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <>
          {(props.files.length || props.files.fileproceed) ?
            <ModalOverlay
              files={props.files.files}
              fileproceed={props.files.fileproceed}
              show={props.files.files.length}
              fileupload={props.fileupload}
            /> : <></>
          }
        </>,
        document.getElementById("overlay-root")
      )}
      {ReactDOM.createPortal(
        <div>
          {isOpenModel ? <Window
            title={"Select file"}
            stage={windowStage}
            onStageChange={handleStageChange}
            onClose={cancelModel}
          >
            <div>
              <div className="form-group mb-3 mb-md-5">
                <label htmlFor="Title">
                  File Name
                </label>
                <input
                  type="text"
                  id="fileName"
                  className="form-control"
                  placeholder="Enter filename"
                  value={fileN}
                  onChange={async (e) => {
                    setFileName(e.target.value)
                    let files = await checkFileExist(e.target.value + "." + fileExt)
                    if (files && (files.dbstatus || files.serverstatus)) {
                      setError("File name or file is already exist")
                    } else {
                      setError("")
                    }
                  }}
                />
                <span className="error-msg" style={{ color: "red" }}>
                  {" "}
                  {fileerror}{" "}
                </span>
              </div>


              <div className="form-group mb-3 mb-md-5">
                <label htmlFor="Title">
                  Network speed
                </label>
                <input
                  type="text"
                  id="networkspeed"
                  className="form-control"
                  placeholder="Your networkspeed"
                  value={uploadSpeed + "MB"}
                  disabled
                />
              </div>


              <input type="file" ref={(ref) => fileRef = ref} onChange={selectImg} />

              <div className="d-flex align-items-center justify-content-end mt-3">
                <div className="form-group">
                  <div className="button-group-container">
                    <a
                      className="btn btn-primary mr-3 mb-sm-0 mb-2"
                      onClick={uploadModel}
                    >
                      <span>Upload</span>
                    </a>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={cancelModel}
                    >
                      <span>Cancel</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Window> : <></>
          }
        </div>,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  files: state.files
});

export default connect(mapStateToProps, { fileupload, callApi })(FileModal);
