import React, { useState } from "react"
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { changeResize, changeTheme } from "../../store/Actions" // Redux actions
import { callApi } from "../../api" // Used for api call
import errorMessages from "../../utils/ErrorMessages" // Error Messages
import {
  validatePassword,
  setItem,
  showMessageNotification,
  showModalNotification,
} from "./../../utils/Functions" // Utility functions
import API from "../../api/Routes"
import { AUTH_URL } from "../../config"
import { CustomStrings } from "../../utils/CustomText"

/******************* 
@Purpose : Used for admin login view
@Parameter : props
@Author : INIC
******************/
function Login(props) {
  const [emailId, setEmailId] = useState(localStorage.getItem("emailId"))
  const [, setIsFormValid] = useState(true)
  var [errors, setErrors] = useState({ emailId: "", password: "" })
  const [password, setPassword] = useState("")
  let [loading, setLoading] = useState(false)
  let [hidden, setHidden] = useState(true)
  /******************* 
  @Purpose : Used for validate form data
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    var mailFormat =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\d{10}$)+$/
    let tempErrors = { emailId: "", password: "" }
    let isFormValid = true
    //for Email
    if (!emailId) tempErrors.emailId = errorMessages.PROVIDE_EMAIL
    else if (!mailFormat.test(emailId))
      tempErrors.emailId = errorMessages.PROVIDE_VALID_EMAIL
    else tempErrors.emailId = ""

    // for password
    if (!password.trim()) tempErrors.password = errorMessages.PROVIDE_PASSWORD
    else if (!validatePassword(password))
      tempErrors.password = errorMessages.PROVIDE_VALID_PASSWORD
    else tempErrors.password = ""

    if (tempErrors.emailId !== "" || tempErrors.password !== "")
      isFormValid = false

    setErrors(tempErrors)
    setIsFormValid(isFormValid)
    return isFormValid
  }

  /******************* 
  @Purpose : Used for rander tooltip data
  @Parameter : passwordProps
  @Author : INIC
  ******************/
  const renderTooltip = (passwordProps) =>
    !hidden ? (
      <Tooltip id="button-tooltip" {...passwordProps}>
        Hide Password
      </Tooltip>
    ) : (
      <Tooltip id="button-tooltip" {...passwordProps}>
        Show Password
      </Tooltip>
  )

  /******************* 
  @Purpose : Used for login action
  @Parameter : passwordProps
  @Author : INIC
  ******************/
  const adminLogin = async (event) => {
    event.preventDefault()
    if (validateForm()) {
      setLoading(true)
      try {
        let body = {
          emailId: emailId,
          password: password,
        }
        const data = await props.callApi(
          API.LOGIN,
          body,
          "post",
          "ADMIN_LOGIN",
          false,
          false,
          AUTH_URL,
        )
        setLoading(false)
        showMessageNotification(data.message + ".", "success")
        setItem("accessToken", data.extra_meta.token)
        props.history.push("/dashboard")
      } catch (error) {
        setLoading(false)
        throw error
      }
    }
  }

  return (
    <div className="App">
      <header className="App-header"></header>
      <div
        className="login-wrapper"
        style={{
          background:
            "url(../assets/images/login-bg.jpg) no-repeat center center",
        }}
      >
        <div className="login-body">
          <div className="login-container row m-0">
            <div className="col-xl-8 col-11">
              <div className="card bg-login-card">
                <div className="row m-0">
                  <div className="col-md-6 col-12 pl-0 pr-0">
                    <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                      <div className="card-header">
                        <div className="card-title text-center mb-4">
                          <h4 className="mb-2">Welcome</h4>
                        </div>
                      </div>
                      <div align="center" className="mb-5 loginRow">
                        <div className="makeLine">
                          <span className="colorGray">LOGIN</span>
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="card-body">
                          <form onSubmit={(evt) => adminLogin(evt)}>
                            <div className="form-group mb-2">
                              <label htmlFor="EmailAddress">
                                Email
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="EmailAddress"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                type="text"
                                className={
                                  errors.emailId
                                    ? "form-control input-error"
                                    : "form-control"
                                }
                                name="emailId"
                                value={emailId}
                                id="email"
                                placeholder="Enter email"
                                onChange={(e) => {
                                  setEmailId(e.target.value)
                                  localStorage.setItem(
                                    "emailId",
                                    e.target.value,
                                  )
                                  errors = Object.assign(errors, {
                                    emailId: "",
                                  })
                                  setErrors(errors)
                                }}
                              />
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              >
                                {errors.emailId}
                              </span>
                            </div>
                            <div className="form-group mb-2 position-relative">
                              <label htmlFor="Password">
                                Password<sup className="text-danger">*</sup>
                              </label>
                              <input
                                className={
                                  errors.password
                                    ? "form-control input-error"
                                    : "form-control"
                                }
                                name="password"
                                value={password}
                                id="password"
                                type={hidden ? "password" : "text"}
                                placeholder=" Enter Password"
                                onChange={(e) => {
                                  setPassword(e.target.value)
                                  errors = Object.assign(errors, {
                                    password: "",
                                  })
                                  setErrors(errors)
                                }}
                              />
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <span
                                  onClick={() => setHidden(!hidden)}
                                  toggle="#password-field"
                                  className={
                                    hidden
                                      ? "bx bx-hide field-icon toggle-password"
                                      : "bx bx-show field-icon toggle-password"
                                  }
                                ></span>
                              </OverlayTrigger>
                              <em className="fa fa-lock" aria-hidden="true" />
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              >
                                {errors.password}
                              </span>
                            </div>
                            <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-center mb-3">
                              <div className="text-left"></div>
                              <div className="text-right">
                                <Link
                                  to="/forgotpassword"
                                  className="card-link AuthTxt"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                            </div>
                            <Button
                              type="button"
                              className="btn btn-primary glow position-relative btn-block"
                              type="submit"
                              onClick={(evt) => adminLogin(evt)}
                            >
                              {loading ? "Loading..." : "Login"}
                              <i className="icon-arrow bx bx-right-arrow-alt"></i>
                            </Button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none text-center align-self-center p-7">
                    <div className="card-content">
                      <img
                        className="img-fluid"
                        src="../assets/images/login.png"
                        alt="login"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(null, { callApi, changeTheme, changeResize })(
  withRouter(Login),
)
