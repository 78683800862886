/******************* 
@Purpose : Used for english langauge default text
@Author : INIC
******************/

export const en = {
  ApiResponse: {
    loginSuccess: "Login Successfully",
    emailSendSuccess: "Email sent successfully",
    resetPasswordSuccess: "Password updated successfully",
  },
  CustomLabels: {
    dashboard: "Dashboard",
    users: "Users",
    hotels: "Hotels",
    adminuser: "Admin Users",
    content: "Content",
    cmspages: "CMS Pages",
    emailTemplateslabel: "Email Templates",
    master: "Master",
    roles: "Roles",
    Transcation: "Transcation",
    media: "Media",
    support: "Support",
    settings: "Settings",
    smtp: "SMTP/SMS Details",
    payment: "Payment Gateway SDK Details",
    staticpages: "Static Pages",
    blogs: "Blog Category",
    faq: "FAQs",
    generalsettings: "General Settings",
    socilamedia: "Social Media",
    SocialSdk: "Social Media SDK Details",
    SocialLinks: "Social Media Links",
    paymentGateway: "Payment Gateway SDK Details",
    emailNotification: "Email Notifications",
    Files: "Files"
  },
  EmailList: {
    emailTemplates: "Email Templates",
    addNewTemplate: "Add New Template",
    templateTitle: "Template Title",
    ID: "ID",
    subject: "Subject",
    createdBy: "Created By",
    dateTime: "Date Time",
    lastUpdated: "Last Updated",
    action: "Action",
    noRecord: "No Records Found!",
  },
  EmailTemplates: {
    emailTemplates: "Email Templates",
    addNewTemplate: "Add New Template",
    templateTitle: "Template Title",
    title: "Title",
    subject: "Subject",
    fromemailAddress: "From email Address",
    tags: "Tags",
  },
  ContentManagement: {
    CMS: {
      edit: "Edit Blog",
      add: "Add Blog",
      content: "Content",
      category: "Category",
      blogTitle: "Blog Title",
      title: "Meta Title",
      slug: "slug",
      metaKeywords: "Meta Keywords",
      metaDescription: "Meta Description",
      blogDescription: "Blog Description",
      image: "Image",
      caption: "Caption",
      postDate: "Post Date",
      tag: "Tag",
      status: "Status",
      author: "Author",
    },
    CMSListing: {
      blog: "Blogs",
      addNewPage: "Add New Blog",
      articleName: "Article Name",
      category: "Category",
      created: "Created",
      status: "Status",
      action: "Action",
      noRecord: "No Records found!",
      search: "Search",
      addNewCMSPage: "Add New Page"
    },
    FAQ: {
      content: "Content",
      faq: "FAQs",
      faqList: "FAQs List",
      faqCategory: "FAQ Category",
      addCategory: "Add Category",
      addNewFAQ: "Add New FAQ",
      category: "Category",
      question: "Question",
      answer: "Answer",
      action: "Action",
      search: "Search",
    },
  },
  Hotelmanagement: {
    hotelname: "Hotel Name",
    hotelsystemadmin: "Hotel System Admin",
    edituser: {
      labels: {
        userprofile: "User Profile",
        firsname: "First Name",
        lastname: "Last Name",
        email: "Email",
        role: "Role",
        status: "Status",
        phoneNumber: "Phone Number",
        birthdate: "Birth date",
        website: "Website",
        gender: "Gender",
        address: "Address",
        country: "Country",
        company: "company",
      },
      buttons: {
        save: "Save",
        saving: "Saving",
        delete: "Delete",
      },
    },
  },
};
