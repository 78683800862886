import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Pagination from "rc-pagination"
import "rc-pagination/assets/index.css"
import swal from "sweetalert"
import { editEmail } from "../../store/Actions"
import { callApi } from "../../api" // Used for api call
import "antd/dist/antd.css"
import Layout from "../../components/Layout/Layout"
import Loader from "../../components/Loader/Loader"
import { useSorting } from "../../hooks"
import { setFavIcon, showMessageNotification } from "./../../utils/Functions" // Utility functions
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api/Routes"
import moment from "moment"
import "moment-timezone"
import $ from "jquery"
import { ADMIN_URL } from "../../config"
import { CustomStrings } from "../../utils/CustomText"
import ReactTooltip from "react-tooltip"
import { Select } from "antd"
$.DataTable = require("datatables.net")
require("datatables.net-responsive")
var { Option } = Select
/******************* 
@Purpose : Used for email tamplet list
@Parameter : props
@Author : INIC
******************/
function EmailTemplate(props) {
  const [page, setPage] = useState(1)
  const [pageSize, setPagesize] = useState(10)
  const [total, setTotal] = useState(1)
  const [length, setLength] = useState(1)
  const [emailTemplateListing, setEmailTemplateListing] = useState([])
  const [sortData, sort, onSort] = useSorting()
  const [searchText, setSearchText] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      await getAllEmailTemplate()
    })()
  }, [page, pageSize, sort])

  const getAllEmailTemplate = async () => {
    setLoading(true)
    var body = {
      page,
      pageSize,
      searchText: searchText,
      sort,
    }
    const response = await props.callApi(
      API.EMAIL_TEMPLATES_LIST,
      body,
      "post",
      null,
      true,
      false,
      ADMIN_URL,
    )
    if (response.data) {
      var result = response.data.map(function (el) {
        var o = Object.assign({}, el)
        o.isChecked = false
        return o
      })

      setLoading(false)
      setEmailTemplateListing(result)
      setTotal(response?.meta?.total)
      setLength(response?.meta?.total)
    }
  }

  /*******************  
  @Purpose : Used for search data
  @Parameter : {}
  @Author : INIC
  ******************/
  const searchField = async (keyword) => {
    setLoading(true)
    var body = {
      pageSize,
      page,
      sort,
      searchText: keyword,
    }
    const response = await props.callApi(
      API.EMAIL_TEMPLATES_LIST,
      body,
      "post",
      null,
      true,
      false,
      ADMIN_URL,
    )
    if (response.data) {
      setEmailTemplateListing(response.data)
      setTotal(response?.meta?.total)
      setLength(response?.meta?.total)
      setLoading(false)
    }
  }

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (page, pagesize) => {
    setPage(page)
    setPagesize(pagesize)
  }

  const deleteEmailTemplate = (etid) => {
    if (etid) {
      swal({
        title: "Are you sure,You Want To Delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const response = await props.callApi(
            API.EMAIL_TEMPLATES_DELETE + etid,
            "",
            "delete",
            null,
            true,
            false,
            ADMIN_URL,
          )

          showMessageNotification(
            response?.extra_meta?.message + ".",
            "success",
          )
          setPage(1)
          setPagesize(10)
          getAllEmailTemplate()
        }
      })
    }
  }

  const numbers = [2, 5, 10, 15, 25, 50, 100, 200, 500].filter(
    (number) => number < total,
  )

  useEffect(() => {
    setFavIcon(document.getElementById("favicon"))
  }, [])

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <nav aria-label="breadcrumb">
              <h3 className="navHeading">Manage Email Templates</h3>
              <ol className="breadcrumb">
                <li className="content-header-title disabled">Navigation</li>
                <li className="content-header-title">Email Templates</li>
                <li className="breadcrumb-item">
                  <span onClick={() => props.history.push("/dashboard")}>
                    <i className="bx bx-home-alt"></i>
                  </span>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Email Templates Listing
                </li>
              </ol>
            </nav>
            <div className="content-area position-relative">
              <div className="grid">
                <div className="grid-content">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="left-buttons d-flex ">
                      <div className="search position-relative has-icon-left">
                        <input
                          type="search"
                          className="form-control text-capitalize"
                          placeholder="search"
                          onChange={(evt) => {
                            searchField(evt.target.value)
                            setSearchText(evt.target.value)
                          }}
                        />
                        <div className="form-control-position">
                          <em className="bx bx-search"></em>
                        </div>
                      </div>
                    </div>

                    <div className="right-buttons">
                      <div className="right-buttons">
                        <div className="right-buttons d-flex justify-content-end">
                          <div
                            className={
                              "custom-dropdown download-dropdown dropdown download-btn"
                            }
                          >
                            <button
                              id="addNewUser"
                              type="button"
                              className="btn glow-primary btn-primary mr-0 minW-md-0"
                            >
                              <i className="bx bx-user-plus d-lg-none"></i>
                              <span
                                className="d-none d-sm-none d-lg-inline-block"
                                onClick={() =>
                                  props.history.push("/add-email-template")
                                }
                              >
                                <i className="bx bx-plus-circle"></i>
                                &nbsp;&nbsp; Add New Email Template
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table row-border nowrap common-datatable"
                      id="content-blog-listing"
                    >
                      <thead>
                        <tr>
                          <th className="all" onClick={() => onSort("title")}>
                            <b>Title</b>{" "}
                            <i
                              aria-hidden="true"
                              className={
                                sortData["title"]
                                  ? "bx bxs-chevron-up"
                                  : "bx bxs-chevron-down"
                              }
                            ></i>
                          </th>
                          <th className="all" onClick={() => onSort("subject")}>
                            <b>Subject</b>
                            <i
                              aria-hidden="true"
                              className={
                                sortData["subject"]
                                  ? "bx bxs-chevron-up"
                                  : "bx bxs-chevron-down"
                              }
                            ></i>
                          </th>
                          <th
                            className="all"
                            onClick={() => onSort("updatedAt")}
                          >
                            <b>Last Updated</b>
                            <i
                              aria-hidden="true"
                              className={
                                sortData["updatedAt"]
                                  ? "bx bxs-chevron-up"
                                  : "bx bxs-chevron-down"
                              }
                            ></i>
                          </th>

                          <th className="all">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {emailTemplateListing.length === 0 ? (
                          <tr className="text-center text-danger not-found-txt">
                            <td colSpan="6">
                              {loading ? (
                                <Loader />
                              ) : (
                                <h6
                                  className="text-center text-danger not-found-txt mt-2"
                                  colSpan="3"
                                >
                                  No Records Found!
                                </h6>
                              )}
                            </td>
                          </tr>
                        ) : (
                          emailTemplateListing.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <span>{item.title}</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <span>{item.subject.slice(0, 30)}</span>
                                  </div>
                                </td>
                                <td>
                                  <span className="dob-date d-block">
                                    {moment(item.updatedAt).format(
                                      "DD MMMM, YYYY HH:mm:ss",
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <ReactTooltip
                                      place="top"
                                      type="dark"
                                      effect="float"
                                    />
                                    <span
                                      className="cursor-pointer mr-3"
                                      data-tip={`Edit`}
                                      onClick={() => {
                                        props.history.push(
                                          `/edit-email-template/${item.id}`,
                                        )
                                      }}
                                    >
                                      <i className="bx bx-edit" />
                                    </span>
                                    {/* <span
                                      className="cursor-pointer mr-3"
                                      data-tip={`Delete`}
                                      onClick={() =>
                                        deleteEmailTemplate(item.id)
                                      }
                                    >
                                      <i className="bx bx-trash-alt" />
                                    </span> */}
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {emailTemplateListing && emailTemplateListing.length > 0 && (
                    <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center justify-content-between">
                      <div>
                        <label className="mr-2">Showing</label>
                        <Select
                          placeholder={<b>10 Items Per Page</b>}
                          optionFilterProp="children"
                          value={
                            emailTemplateListing.length > 10
                              ? pageSize
                              : emailTemplateListing.length
                          }
                          onChange={(value) => {
                            setPage(1)
                            setPagesize(value)
                          }}
                        >
                          {numbers.map((number) => (
                            <Option value={number} key={number}>
                              {number}
                            </Option>
                          ))}
                          <Option value={total} key={total}>
                            {total}
                          </Option>
                        </Select>
                        <label className="ml-2">
                          Out of {total} Email Templates
                        </label>
                      </div>
                      <div className="pagination-list mt-2 mt-sm-3">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pageSize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2020 © IndiaNIC</span>
              <span className="d-flex align-items-center">
                Crafted with{" "}
                <i className="bx bxs-heart text-danger ml-1 mr-1" /> in INDIA{" "}
              </span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  EmailTemplateAccess: { edit: true, delete: true, create: true },
  language: state.admin.language,
  adminData: state.admin.adminData,
  editAdminProfileData: state.admin.editAdminProfileData,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default withRouter(
  connect(mapStateToProps, { callApi, editEmail })(EmailTemplate),
)
