import React, { Suspense } from "react"
import { connect } from "react-redux"
import { getItem, setItem } from "./utils/Functions"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
// Authentication pages
import AdminLogin from "./pages/Authentication/AdminLogin"
import ResetPassword from "./pages/Authentication/ResetPassword"
import ChangePassword from "./pages/Authentication/ChangePassword"
import ForgotPassword from "./pages/Authentication/ForgotPassword"
// Dashboard page
import Dashboard from "./pages/Dashboard/Dashboard"
// Profile page
import Profile from "./pages/Profile/Profile"
// Hotel page
import HotelsList from "./pages/Hotels/HotelsList"
import AddUser from "./pages/Hotels/AddHotel"
import DetailHotel from "./pages/Hotels/DetailHotel"
// Contents page
import CmsListing from "./pages/CMS/CMSListing"
import EditCMS from "./pages/CMS/EditCMS"
import AddCms from "./pages/CMS/AddCMS"
// EmailsTemplates page
import AddEmailTemplate from "./pages/EmailsTemplates/AddEmailTemplate"
import EditEmailTemplate from "./pages/EmailsTemplates/EditEmailTemplate"
import EmailsList from "./pages/EmailsTemplates/EmailsList"
// 404 page
import FourZeroFour from "./pages/FourZeroFour/FourZeroFour"
// Offline page
import OfflinePage from "./pages/Offline/Offline"

// components
import Sidebar from "./components/Layout/SideBar"
import { Offline, Online } from "react-detect-offline"
//notification library
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// customized design intigration
import "./scss/styles.scss"
import FileModal from "./components/FileModal/FileModal"
import { useAuth0 } from "@auth0/auth0-react"
import { AUTH_URL } from "./config"
import axios from "axios"
import API from "./api/Routes"

/******************* 
@Purpose : This page is default page for our project
@Parameter : {}
@Author : INIC
******************/
function App() {
  /******************* 
  @Purpose : Used for token authorization
  @Parameter : {}
  @Author : INIC
  ******************/

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const publicRouteUrl = ["/resetpassword", "/forgotpassword"]

  const Authorization = () => {
    if (isAuthenticated) {
      return true
    } else {
      return getItem("accessToken") ? true : false
    }
  }

  const authcheck = () => {
    getAccessTokenSilently().then((tokend) => {
      let url = AUTH_URL + API.AUTH_CHECK
      let headers = { "Content-Type": "application/json" }
      axios({
        method: "post",
        url,
        headers,
        data: { token: tokend, user },
      }).then((response) => {
        let { data: responseData } = response
        if (response && responseData.status === 1) {
          setItem("accessToken", responseData.access_token)
          return <Redirect to="/dashboard" />
        }
      })
    })
  }

  /******************* 
  @Purpose : Used for private routing
  @Parameter : {Component1, ...rest}
  @Author : INIC
  ******************/
  const PrivateRoute = ({ component: Component1,path, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Component1 {...props} />
        ) : publicRouteUrl.includes(path) ? (
          <Redirect to="/" />
        ) : (
          <FourZeroFour {...props} />
        )
      }
    />
  )

  
  /******************* 
  @Purpose : Used for check user is already login or not
  @Parameter : {Component2, ...rest }
  @Author : INIC
  ******************/
  const LoginCheckRoute = ({ component: Component2, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          authcheck()
        ) : Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component2 {...props} />
        )
      }
    />
  )

  /******************* 
  @Purpose : Used for check reset password
  @Parameter : {Component3, ...rest}
  @Author : INIC
  ******************/
  const GetPublicRouteRoute = ({ component: Component3, path, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : 
          <Component3 {...props} />
      }
    />
  )

  return (
    <Suspense fallback="loading">
      <Offline>
        <OfflinePage />
      </Offline>
      <Online>
        <FileModal></FileModal>
        <Router>
          <Sidebar />
          <div style={{ textAlign: "center" }}>
            <ToastContainer />
          </div>
          <Switch>
            <LoginCheckRoute exact path="/" component={AdminLogin} />
            <GetPublicRouteRoute
              exact
              path="/resetpassword"
              component={ResetPassword}
            />
            <GetPublicRouteRoute
              exact
              path="/forgotpassword"
              component={ForgotPassword}
            />
            <exact path="/forgotpassword" component={ForgotPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/profile" component={Profile} />

            <PrivateRoute
              exact
              path="/hotel-management"
              component={HotelsList}
            />
            <PrivateRoute exact path="/add-hotel" component={AddUser} />
            <PrivateRoute
              exact
              path="/detail-hotel/:slug"
              component={DetailHotel}
            />

            <PrivateRoute exact path="/edit-cms/:slug" component={EditCMS} />
            <PrivateRoute exact path="/add-cms" component={AddCms} />
            <PrivateRoute exact path="/cms-management" component={CmsListing} />

            <PrivateRoute
              exact
              path="/email-templates"
              component={EmailsList}
            />
            <PrivateRoute
              exact
              path="/add-email-template"
              component={AddEmailTemplate}
            />
            <PrivateRoute
              exact
              path="/edit-email-template/:slug"
              component={EditEmailTemplate}
            />

            <PrivateRoute exact path="/files" component={FileList} />

            <PrivateRoute path="/*" component={FourZeroFour} />
          </Switch>
        </Router>
      </Online>
    </Suspense>
  )
}

/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps)(App)
