import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header.jsx";
/******************* 
@Purpose : Used for default view design
@Parameter : props
@Author : INIC
******************/
function Layout(props) {

  return (
    <div className="container-scroller">
      <Header />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  sidebar: state.admin.sidebar,
  admindata: state.admin.adminData,
  editadminprofile: state.admin.editAdminProfileData,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, null)(withRouter(Layout));
