import React, { useState, useRef, useEffect } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link, withRouter } from "react-router-dom"
import PerfectScrollbar from "react-perfect-scrollbar"
import { sidebaropen } from "../../store/Actions" // Commun function
import { callApi } from "../../api" // Used for api call
/******************* 
@Purpose : Used for sidebar view
@Parameter : props
@Author : INIC
******************/
function Sidebar(props) {
  const [lang] = useTranslation("language")
  const [usermenu, openUsermenu] = useState(false)
  const [settingsmenu, openSettingsmenu] = useState(false)
  const [contentmenu, openContentmenu] = useState(false)
  const [blogsmenu, openBlogsmenu] = useState(false)
  const path = props.location.pathname
  const innerRef = useOuterClick()

  function useOuterClick(callback) {
    const outerRef = useRef()
    const callbackRef = useRef()

    useEffect(() => {
      callbackRef.current = callback
    })

    useEffect(() => {
      document.addEventListener("click", handleClick)
      return () => document.removeEventListener("click", handleClick)
      function handleClick(e) {
        if (
          outerRef.current &&
          callbackRef.current &&
          !outerRef.current.contains(e.target)
        ) {
          callbackRef.current(e)
        }
      }
    }, [])
    return outerRef
  }

  /******************* 
  @Purpose : Used for sidebar open
  @Parameter : {}
  @Author : INIC
  ******************/
  const SidebarOpen = () => {
    document.body.classList.remove("sidebar-open")
  }
  /******************* 
  @Purpose : Used for change routes
  @Parameter : e, page
  @Author : INIC
  ******************/
  const changeRoute = (e, page) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (
      page === "/dashboard" ||
      page === "/rolesList" ||
      page === "/master" ||
      page === "/emailTemplates"
    ) {
      openUsermenu(false)
      openContentmenu(false)
      openSettingsmenu(false)
    }

    if (page === "/faq") {
      openBlogsmenu(false)
    }
    document.body.classList.remove("sidebar-open")
    return props.history.push(page)
  }
 
  /******************* 
  @Purpose : Used for settings handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const getActiveLinkClass = (activePath, type) => {
    // used for active route class on path
    if (type === "base-path") {
      if (path === activePath) {
        return "menu-link active-link"
      }
      return "menu-link"
    }
    // used for active user route class
    if (type === "user-parent") {
      if (activePath === "showUserMenu" && usermenu) {
        return "menu-list-item has-child-submenu level1 submenu-open"
      }
      return "menu-list-item has-child-submenu level1"
    }

    if (type === "user-child") {
      if (activePath === "showUserMenu" && usermenu) {
        return "sidebar-menu-list sub-menu-list show"
      }
      return "sidebar-menu-list sub-menu-list"
    }
    // used for active content route class
    if (type === "content-parent") {
      if (activePath === "contentmenu" && contentmenu) {
        return "menu-list-item has-child-submenu level1 submenu-open"
      }
      return "menu-list-item has-child-submenu level1"
    }

    if (type === "content-child") {
      if (activePath === "contentmenu" && contentmenu) {
        return "sidebar-menu-list sub-menu-list show"
      }
      return "sidebar-menu-list sub-menu-list"
    }
    // used for active blog route class
    if (type === "blogs-parent") {
      if (activePath === "blogsmenu" && blogsmenu) {
        return "menu-list-item has-child-submenu level1 submenu-open"
      }
      return "menu-list-item has-child-submenu level1"
    }
    // setting for active content route class
    if (type === "settings-parent") {
      if (activePath === "settingsmenu" && settingsmenu) {
        return "menu-list-item has-child-submenu level1 submenu-open"
      }
      return "menu-list-item has-child-submenu level1"
    }
  }
  let isLogined = localStorage.getItem("accessToken")
  return (
    <div>
      {isLogined && (
        <div ref={innerRef} id="container" className="App">
          <header className="App-header"></header>
          <aside className="sidebar">
            <div className="sidebar-inner-wrapper">
              <div className="sidebar-heading">
                <div className="d-flex align-items-end justify-content-between w-100">
                  <a href="" className="navbar-brand">
                    <div className="brand-logo">
                      <img
                        className="img-fluid"
                        src="/assets/images/logo-xenia.png"
                        alt="branding logo"
                      />
                    </div>
                  </a>
                  <span onClick={SidebarOpen} className="sidebar-close">
                    <em className="bx bx-x"></em>
                  </span>
                </div>
              </div>
              <PerfectScrollbar>
                <div className="sidebar-menu">
                  <ul className="sidebar-menu-list">
                    <li className="menu-list-item">
                      <a
                        className={getActiveLinkClass(
                          "/dashboard",
                          "base-path",
                        )}
                        onClick={(e) => {
                          changeRoute(e, "/dashboard")
                        }}
                      >
                        <i className="bx bx-home-alt" />
                        <span className="menu-title">
                          {lang("CustomLabels.dashboard")}
                        </span>
                      </a>
                    </li>

                    <li className="menu-list-item">
                      <a
                        className={getActiveLinkClass(
                          "/hotel-management",
                          "base-path",
                        )}
                        onClick={(e) => {
                          changeRoute(e, "/hotel-management")
                        }}
                      >
                        <i className="bx bxs-hotel" />
                        <span className="menu-title">
                          {lang("CustomLabels.hotels")}
                        </span>
                      </a>
                    </li>

                    <li className="menu-list-item">
                      <a
                        className={getActiveLinkClass(
                          "/cms-management",
                          "base-path",
                        )}
                        onClick={(e) => {
                          changeRoute(e, "/cms-management")
                        }}
                      >
                        <i className="bx bxs-file" />
                        <span className="menu-title">
                          {lang("CustomLabels.cmspages")}
                        </span>
                      </a>
                    </li>
                
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/email-templates")
                        }}
                        className={getActiveLinkClass(
                          "/email-templates",
                          "base-path",
                        )}
                      >
                        <i className="bx bx-notification" />
                        <span className="menu-title">
                          {lang("CustomLabels.emailTemplateslabel")}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </PerfectScrollbar>
            </div>
          </aside>
        </div>
      )}
    </div>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  admindata: state.admin.adminData,
  sidebar: state.admin.sidebar,
  editadminprofile: state.admin.editAdminProfileData,
  rolePermissions: state?.admin?.adminData?.staticRolePermission,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi, sidebaropen })(
  withRouter(Sidebar),
)
