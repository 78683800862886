import React, { useState, useEffect, useRef } from "react"
import { callApi } from "../../api" // Used for api call
import {
  validateEmail,
  showMessageNotification,
  validateMobileNumberAllCountry,
  validateName,
  setFavIcon,
} from "./../../utils/Functions" // Utility functions
import { Button } from "react-bootstrap"
import Select from "react-select"
import { isEmpty } from "lodash"
import PhoneInput from "react-phone-input-2"
import { connect } from "react-redux"
import errorMessages from "../../utils/ErrorMessages"
import countryList from "react-select-country-list"
import API from "../../api/Routes"
import CropImagesProfile from "../../components/CropImages/CropImagesProfile"
import { ADMIN_URL } from "../../config"
import "react-datepicker/dist/react-datepicker.css"
import "react-phone-input-2/lib/style.css"
import Layout from "../../components/Layout/Layout"
/******************* 
@Purpose : Used for profile view
@Parameter : props
@Author : INIC
******************/
function Profile({ admindata, callApi, history }) {
  const options = countryList().getData()
  const [formData, setFormData] = useState({})
  const [isProfileEdit, setIsProfileEdit] = useState(false)
  let [loading, setLoading] = useState(false)
  const [numberFormat, setNumberFormat] = useState("+. (...) ...-....")
  let [photoUpload, setphotoUpload] = useState("")
  let [photoUploadBaseUrl, setphotoUploadBaseUrl] = useState("")
  let [errorFile, setErrorFile] = useState("")
  let {
    firstName,
    lastName,
    email,
    mobile,
    gender,
    country,
    photo,
    id,
    errors = {},
  } = formData
  const genderOption = [
    { value: "Female", label: "Female" },
    { value: "Male", label: "Male" },
  ]

  useEffect(() => {
    setFormData({ ...formData, photo: photoUpload })
  }, [photoUpload])

  useEffect(() => {
    const defaultFormat = formData?.mobile?.replace(/[0-9]/g, ".")
    setNumberFormat(defaultFormat)
    setFavIcon(document.getElementById("favicon"))
  }, [])

  useEffect(() => {
    setFormData({
      id: admindata?.id,
      firstName: admindata?.firstName,
      lastName: admindata?.lastName,
      email: admindata?.emailId,
      gender: {
        label: admindata?.gender,
        value: admindata?.gender,
      },
      photo: admindata?.photo,
      mobile: admindata?.mobile,
      country: {
        label: admindata?.country,
        value: admindata?.country,
      },
      errors: {},
    })
  }, [admindata])

  /******************* 
  @Purpose : Used for validate form field
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    // for firstName
    if (formData.hasOwnProperty("firstName")) {
      if (isEmpty(firstName))
        errors.firstName = errorMessages.PROVIDE_FIRST_NAME
      else if (!validateName(firstName))
        errors.firstName = errorMessages.PROVIDE_VALID_FIRST_NAME
      else delete errors.firstName
    }
    //for lastName
    if (formData.hasOwnProperty("lastName")) {
      if (isEmpty(lastName)) errors.lastName = errorMessages.PROVIDE_LAST_NAME
      else if (!validateName(lastName))
        errors.lastName = errorMessages.PROVIDE_VALID_LAST_NAME
      else delete errors.lastName
    }
    // for email
    if (formData.hasOwnProperty("email")) {
      if (isEmpty(email)) errors.email = errorMessages.PROVIDE_EMAIL
      else if (!validateEmail(email))
        errors.email = errorMessages.PROVIDE_VALID_EMAIL
      else delete errors.email
    }

    //	Mobile number
    if (isEmpty(formData.mobile) && formData.hasOwnProperty("mobile"))
      errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER
    else if (!validateMobileNumberAllCountry(numberFormat, formData.mobile)) {
      errors.mobile = `${errorMessages.PROVIDE_VALID_FORMAT}`
    } else if (
      formData.mobile.split("0").length - 1 ===
      formData.mobile.length
    ) {
      errors.mobile = `${errorMessages.PROVIDE_VALID_FORMAT}`
    } else {
      delete errors.mobile
    }

    // for gender
    if (formData.hasOwnProperty("gender")) {
      if (gender.value == null) errors.gender = errorMessages.PROVIDE_GENDER
      else delete errors.gender
    }

    // for country
    if (formData.hasOwnProperty("country")) {
      if (country.value == null)
        errors.country = errorMessages.PROVIDE_COUNTRY_NAME
      else delete errors.country
    }

    //for photo
    if (formData.hasOwnProperty("photo")) {
      if (photo === "" || photo === null)
        errors.photo = errorMessages.PROVIDE_PROFILE_PIC
      else delete errors.photo
    }

    const isFormvalid = Object.keys(errors).length !== 0 ? false : true
    setFormData({
      ...formData,
      errors: errors,
    })
    return isFormvalid
  }
  /******************* 
  @Purpose : Used for form data change
  @Parameter : e
  @Author : INIC
  ******************/
  const handleChange = (e) => {
    if (e.target.value) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      })
    } else {
      setFormData({
        ...formData,
        [e.target.name]: "",
      })
    }
  }

  /******************************************************************************************
	  @Purpose    :   To change the mobile number with country code and number
	  *******************************************************************************************/
  const handleMobileNumberChange = (value, countryData) => {
    const { dialCode, format } = countryData
    const phoneNumber = value.slice(dialCode.length)
    setFormData({
      ...formData,
      mobile: `+${dialCode}-${phoneNumber}`,
    })
    setNumberFormat(format)
  }

  /******************* 
  @Purpose : Used for select country
  @Parameter : val
  @Author : INIC
  ******************/
  const handleCountrySelector = (val) => {
    country = val
    setFormData({
      ...formData,
      country: country,
    })
  }
  /******************* 
  @Purpose : Used for edit admin profile
  @Parameter : e
  @Author : INIC
  ******************/
  const editAdminProfile = async (e) => {
    e.preventDefault()
    let body = Object.assign({}, formData)
    delete body.email
    body.country = body.country && body.country.label && body.country.label
    body.gender = body.gender && body.gender.label
    body.user_id = body.id
    body.photo = photoUpload.replace(photoUploadBaseUrl, "")
    body = Object.assign(body)
    delete body.errors
    delete body.id
    if (validateForm()) {
      setLoading(true)
      const response = await callApi(
        API.UPDATE_PROFILE,
        body,
        "post",
        "EDITADMIN_PROFILE",
        true,
        false,
        ADMIN_URL,
      )
      setLoading(false)
      setIsProfileEdit(false)
      showMessageNotification(response?.extra_meta?.message + ".", "success")
    }
  }
  /******************* 
  @Purpose : Used for remove photos
  @Parameter : e
  @Author : INIC
  ******************/
  const removePhoto = async (e) => {
    e.preventDefault()
    var body = {
      firstName: firstName,
      lastName: lastName,
      mobile: mobile,
      photo: "/",
      gender: gender.value,
      country: country.value,
      user_id: id,
    }
    const response = await callApi(
      API.UPDATE_PROFILE,
      body,
      "post",
      "EDITADMIN_PROFILE",
      true,
      false,
      ADMIN_URL,
    )

    showMessageNotification("Profile removed successfully.", "success")
  }
  /******************* 
  @Purpose : Used for change gender
  @Parameter : event
  @Author : INIC
  ******************/
  const genderHandler = (event) => {
    if (event.value && event.label) {
      setFormData({
        ...formData,
        gender: event,
      })
    } else {
      setFormData({
        ...formData,
        gender: "",
      })
    }
  }

  let imagePreview = null
  if (photo !== "/" && photo !== null) {
    imagePreview = <img src={photo ? photo : ""} alt={photo} />
  } else {
    imagePreview = <img src={"/assets/images/avatar-s-16.jpg"} alt="Icon" />
  }

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form className="mb-7">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <nav aria-label="breadcrumb">
                  <h3 className="navHeading">Manage User Profile</h3>
                  <ol className="breadcrumb">
                    <li className="content-header-title disabled">
                      Navigation
                    </li>
                    <li className="content-header-title">Profile</li>
                    <li className="breadcrumb-item">
                      <span onClick={() => history.push("/dashboard")}>
                        <i className="bx bx-home-alt"></i>
                      </span>
                    </li>
                    <li className="breadcrumb-item">Update Profile</li>
                  </ol>
                </nav>
                <div className="btn-blocks mb-md-0 mb-2">
                  {isProfileEdit ? (
                    <>
                      <a
                        className="btn btn-primary glow-primary mr-3"
                        onClick={(e) => editAdminProfile(e)}
                      >
                        <em className="bx bx-revision mr-2" />
                        {loading ? "Updating" : "Update"}
                      </a>
                      <a
                        className="btn btn-outline-primary btnColorWhite"
                        onClick={(e) => {
                          setIsProfileEdit(false)
                          setFormData({ ...formData, errors: {} })
                          setErrorFile("")
                        }}
                      >
                        Cancel
                      </a>
                    </>
                  ) : (
                    <a
                      className="btn btn-primary glow-primary mr-3"
                      onClick={(e) => setIsProfileEdit(true)}
                    >
                      <em className="bx bx-edit mr-2" />
                      Edit Profile
                    </a>
                  )}
                </div>
              </div>
              <div className="card profile-card mb-5">
                <div className="row">
                  <div className="col-md-2">
                    <div className="edit-image">
                      <div
                        className={`user-image ${
                          errors.photo || errorFile !== "" ? "errorBorder" : ""
                        }`}
                      >
                        {imagePreview}
                        {isProfileEdit && (
                          <label className="img-upload" htmlFor="attach-file">
                            <CropImagesProfile
                              setphotoUpload={setphotoUpload}
                              setphotoUploadBaseUrl={setphotoUploadBaseUrl}
                              setErrorFile={setErrorFile}
                              validateForm={validateForm}
                            />
                            <em className="bx bxs-edit-alt" />
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="mt-3 mt-sm-4">
                      {isProfileEdit &&
                        (imagePreview.props.src !==
                        "/assets/images/avatar-s-16.jpg" ? (
                          <Button
                            className="btn btn-primary"
                            onClick={(e) => removePhoto(e)}
                          >
                            Remove photo
                          </Button>
                        ) : null)}
                      <span className="text-danger d-block w-100 mt-1">
                        {errorFile === "" ? errors.photo : ""}
                        {errorFile !== "" ? errorFile : ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-lg-12 col-xl-6 mt-3">
                        <div className="user-title-info user-details">
                          <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                            <label
                              className="mb-0 xl-label"
                              htmlFor="firstName"
                            >
                              First Name<sup className="text-danger">*</sup>
                            </label>
                            <div className="w-100">
                              {isProfileEdit ? (
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.firstName ? "errorBorder" : ""
                                  }`}
                                  id="firstName"
                                  name="firstName"
                                  placeholder="Enter first name"
                                  value={firstName}
                                  onChange={(e) => handleChange(e)}
                                />
                              ) : (
                                <span className="profilespan">
                                  {admindata?.firstName}
                                </span>
                              )}
                              <span className="text-danger d-block">
                                {errors.firstName}
                              </span>
                            </div>
                          </div>

                          <div className="form-group d-flex align-items-center mb-3">
                            <label className="mb-0 xl-label" htmlFor="lastName">
                              Last Name<sup className="text-danger">*</sup>
                            </label>
                            <div className="w-100">
                              {isProfileEdit ? (
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.lastName ? "errorBorder" : ""
                                  }`}
                                  id="lastName"
                                  name="lastName"
                                  placeholder="Enter last name"
                                  value={lastName}
                                  onChange={(e) => handleChange(e)}
                                />
                              ) : (
                                <span className="profilespan">
                                  {admindata?.lastName}
                                </span>
                              )}
                              <span className="text-danger d-block">
                                {errors.lastName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-6  mt-3">
                        <div className="user-title-info user-details">
                          <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                            <label className="mb-0 xl-label" htmlFor="Email">
                              Email<sup className="text-danger">*</sup>
                            </label>
                            <div className="w-100">
                              {isProfileEdit ? (
                                <input
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Enter email"
                                  disabled
                                  type="email"
                                  value={email}
                                  onChange={(e) => handleChange(e)}
                                />
                              ) : (
                                <span className="profilespan">
                                  {admindata?.emailId}
                                </span>
                              )}
                              <span className="text-danger d-block">
                                {errors.email}
                              </span>
                            </div>
                          </div>

                          <div className="form-group d-flex align-items-center  mb-3">
                            <label className="mb-0 xl-label" htmlFor="Email">
                              Phone number<sup className="text-danger"></sup>
                            </label>
                            <div className="w-100">
                              {isProfileEdit ? (
                                <PhoneInput
                                  containerClass={`${
                                    errors.mobile
                                      ? "errorBorder borderCorner"
                                      : ""
                                  }`}
                                  country={"us"}
                                  countryCodeEditable={false}
                                  name="phone"
                                  value={mobile}
                                  enableAreaCodes={true}
                                  // enableLongNumbers={true}
                                  onChange={handleMobileNumberChange}
                                  enableAreaCodeStretch={true}
                                />
                              ) : (
                                <span className="profilespan">
                                  {admindata?.mobile}
                                </span>
                              )}
                              <span className="text-danger d-block w-100 mt-1">
                                {errors.mobile}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-6 mt-3">
                        <div className="user-title-info user-details">
                          <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                            <label
                              className="mb-0 xl-label"
                              htmlFor="firstName"
                            >
                              Country<sup className="text-danger">*</sup>
                            </label>
                            <div className="w-100">
                              {isProfileEdit ? (
                                <Select
                                  data-live-search="true"
                                  id="country"
                                  className={`selectpicker w-100 ${
                                    errors.country
                                      ? "errorBorder borderCorner"
                                      : ""
                                  }`}
                                  name="country"
                                  placeholder={"Select Country"}
                                  value={
                                    country?.label !== null &&
                                    country?.value !== null
                                      ? country
                                      : undefined
                                  }
                                  options={options}
                                  onChange={handleCountrySelector}
                                />
                              ) : (
                                <span className="profilespan">
                                  {admindata?.country}
                                </span>
                              )}
                              <span className="text-danger d-block">
                                {errors.country}
                              </span>
                            </div>
                          </div>

                          <div className="form-group d-flex align-items-center mb-md-5 mb-3">
                            <label className="mb-0 xl-label" htmlFor="lastName">
                              Gender<sup className="text-danger">*</sup>
                            </label>
                            <div className="w-100">
                              {isProfileEdit ? (
                                <Select
                                  id="gender"
                                  name="gender"
                                  placeholder={"Select Gender"}
                                  value={
                                    gender?.label !== null &&
                                    gender?.value !== null
                                      ? gender
                                      : undefined
                                  }
                                  className={`selectpicker w-100 ${
                                    errors.gender
                                      ? "errorBorder borderCorner"
                                      : ""
                                  }`}
                                  options={genderOption}
                                  onChange={(e) => genderHandler(e)}
                                ></Select>
                              ) : (
                                <span className="profilespan">
                                  {admindata?.gender}
                                </span>
                              )}
                              <span className="text-danger d-block">
                                {errors.gender}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* {ifShowFileModal && (
        <FileModal
          title="File Upload"
          message="Select an file"
        />
      )} */}
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2021 © IndiaNIC</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  admindata: state.admin.adminData,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(Profile)
