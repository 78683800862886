import React, { useEffect, useState, useRef } from "react"
import Layout from "../../components/Layout/Layout"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { callApi } from "../../api" // Used for api call
import { Link } from "react-router-dom"
import API from "../../api/Routes"
import StaticEditorEdit from "../../components/Editor/BlogEditorEdit"
import { showMessageNotification } from "../../utils/Functions" //Utility functions
import { ADMIN_URL } from "../../config"
import ErrorMessages from "../../utils/ErrorMessages"
import { CustomStrings } from "../../utils/CustomText"
import { ckEditorConfig } from "../../utils/CustomText"
import CKEditor from "react-ckeditor-component"

/******************* 
@Purpose : Used for static CMS pages
@Parameter : props
@Author : INIC
******************/
function EditCMS(props) {
  const [lang] = useTranslation("language")
  const editorRef = useRef()

  const [gjsHtml, setGHtml] = useState("")
  const [pageTitle, setPageTitle] = useState("")
  let [errors, setErrors] = useState({})
  let [loading, setLoading] = useState(false)

  const pageTitleTrackChanges = useRef(null)
  const staticId = props.match.params.slug

  useEffect(() => {
    if (staticId) {
      getCMSDetails(staticId)
    }
    setFavIcon(document.getElementById("favicon"))
  }, [])

  /******************* 
  @Purpose : Used for page title change
  @Parameter : e
  @Author : INIC
  ******************/
  const handlePageTitleChange = (e) => {
    let maxChar = 70
    pageTitleTrackChanges.current.textContent = `Maximum ${
      maxChar - e.target.value.length
    } characters is suitable`
    setPageTitle(e.target.value)
    errors = Object.assign(errors, { pageTitle: "" })
    setErrors(errors)
  }

  /******************* 
  @Purpose : Used for Validate Form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    var titleFormat = /^[a-zA-Z0-9 ]{5,70}$/
    let tempErrors = { pageTitle: "", gjsHtml: "" }
    let isFormValid = true

    //for page title
    if (!pageTitle) {
      tempErrors.pageTitle = ErrorMessages.PROVIDE_PAGE_TITLE
    } else if (!titleFormat.test(pageTitle)) {
      tempErrors.pageTitle = ErrorMessages.PROVIDE_VALID_PAGE_TITLE
    } else {
      tempErrors.pageTitle = ""
    }

    //for page title
    if (gjsHtml.length < 50) {
      tempErrors.gjsHtml = ErrorMessages.PROVIDE_AT_LEAST_FIFTY_CHAR
    } else {
      tempErrors.gjsHtml = ""
    }

    if (tempErrors.pageTitle !== "" || tempErrors.gjsHtml !== "") {
      isFormValid = false
    }

    setErrors(tempErrors)
    return isFormValid
  }

  /******************* 
  @Purpose : Used for get details
  @Parameter : id
  @Author : INIC
  ******************/
  const getCMSDetails = async (id) => {
    const response = await props.callApi(
      API.CMS_DETAILS + id,
      "",
      "get",
      null,
      true,
      false,
      ADMIN_URL,
    )
    if (response.data) {
      setGHtml(response.data?.content)
      setPageTitle(response.data?.title)
    }
  }

  /******************* 
  @Purpose : Used for Update Static Page
  @Parameter : e
  @Author : INIC
  ******************/
  const updateCmsPage = async (e) => {
    try {
      e.preventDefault()
      if (validateForm()) {
        setLoading(true)
        let body = {
          title: pageTitle,
          content: gjsHtml,
        }
        const response = await props.callApi(
          API.UPDATE_CMS + staticId,
          body,
          "patch",
          null,
          true,
          false,
          ADMIN_URL,
        )

        showMessageNotification(response?.extra_meta?.message, "success")
        setTimeout(() => {
          props.history.push("/cms-management")
        }, 1500)
      }
    } catch (error) {
      throw error
    }
  }

  /******************* 
  @Purpose : Used for preview
  @Parameter : content
  @Author : INIC
  ******************/
  const getpreview = (content) => {
    setGHtml(content)
  }

  const getFavEl = () => {
    return document.getElementById("favicon")
  }
  const setFavIcon = () => {
    const favicon = getFavEl() // Accessing favicon element
    if (favicon) {
      favicon.href = CustomStrings.FaviconIconUrl
    }
  }
  const onChangeCkEditor = (evt) => {
    var html = evt.editor.getData()
    setGHtml(html)
  }

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <nav aria-label="breadcrumb">
              <h3 className="navHeading">Manage CMS Pages</h3>
              <ol className="breadcrumb">
                <li className="content-header-title disabled">Navigation</li>
                <li className="content-header-title">
                  <span onClick={() => props.history.push("/cms-management")}>
                    CMS
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link onClick={() => props.history.push("/dashboard")}>
                    <i className="bx bx-home-alt"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit CMS
                </li>
              </ol>
            </nav>
            <div className="d-flex align-items-center justify-content-end mb-2 flex-wrap">
              <span className="author d-lg-none d-flex">
                {lang("ContentManagement.CMS.Author")}
              </span>
            </div>
            <div className="blog-content-block">
              <div className="card card-media">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-12">
                    <div className="form-group mb-md-5 mb-3">
                      <label htmlFor="pageTitle">
                        Page Title<sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="pageTitle"
                        name="pageTitle"
                        placeholder="IndiaNIC Joins the Clutch 1000!"
                        value={pageTitle}
                        maxLength="70"
                        onChange={(e) => handlePageTitleChange(e)}
                      />
                      <small
                        id="pageTitle"
                        className="form-text"
                        ref={pageTitleTrackChanges}
                      >
                        Maximum {70 - pageTitle.length} characters is suitable
                      </small>
                      <span className="text-danger d-block">
                        {errors.pageTitle}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="blog-content">
                  <div className="img-drag-section">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-0">
                          <label htmlFor="caption">
                            {lang("ContentManagement.CMS.content")}
                            <sup className="text-danger">*</sup>
                          </label>

                          {/* <StaticEditorEdit
                            className="form-control mx-auto"
                            editContent={gjsHtml}
                            ref={editorRef}
                            getPreview={getpreview}
                          /> */}

                          <CKEditor
                            activeClass="p10"
                            content={gjsHtml}
                            config={ckEditorConfig}
                            events={{
                              // blur: this.onBlur,
                              afterPaste: (data) => {
                                onChangeCkEditor(data)
                              },
                              change: (data) => {
                                onChangeCkEditor(data)
                              },
                            }}
                          />
                          <span className="text-danger d-block">
                            {errors.gjsHtml}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-3">
                  <div className="form-group">
                    <div className="button-group-container">
                      {
                        <React.Fragment>
                          <a
                            className="btn btn-primary mr-3 mb-sm-0 mb-2"
                            onClick={updateCmsPage}
                          >
                            <span> {loading ? "Updating" : "Update"}</span>
                          </a>
                        </React.Fragment>
                      }
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          props.history.push("/cms-management")
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  // CmsPagesAccess: state.admin.adminData.staticRolePermission.cmsPagesAccess,
  CmsPagesAccess: { edit: true, delete: true, create: true },
  admindata: state.admin.adminData,
  language: state.admin.language,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(EditCMS)
