import React, { useState, useEffect, useRef } from "react"
import Layout from "../../components/Layout/Layout"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { callApi } from "../../api" // Used for api call
import { Link, withRouter } from "react-router-dom"
import { Select } from "antd"
import StaticEditorEdit from "../../components/Editor/BlogEditorEdit"
import API from "../../api/Routes"
import { setFavIcon, showMessageNotification } from "../../utils/Functions" //Utility functions
import { ADMIN_URL } from "../../config"
import ErrorMessages from "../../utils/ErrorMessages"
import { ckEditorConfig, CustomStrings } from "../../utils/CustomText"
import CKEditor from "react-ckeditor-component"
/******************* 
@Purpose : Used for email tamplet
@Parameter : props
@Author : INIC
******************/
function EditEmailTemplate(props) {
  const [lang] = useTranslation("language")

  const [subject, setSubject] = useState("")
  const editorRef = useRef()
  const [gjsHtml, setGHtml] = useState("")
  const [emailTitle, setEmailTitle] = useState("")
  let [errors, setErrors] = useState({})
  const emailTitleTrackChanges = useRef(null)
  const emailSubjectTrackChanges = useRef(null)
  let [loading, setLoading] = useState(false)
  const staticId = props.match.params.slug

  useEffect(() => {
    if (staticId) {
      getEmailTemplateDetails(staticId)
    }
    setFavIcon(document.getElementById("favicon"))
  }, [])

  /******************* 
  @Purpose : Used for get details
  @Parameter : id
  @Author : INIC
  ******************/
  const getEmailTemplateDetails = async (id) => {
    const response = await props.callApi(
      API.EMAIL_TEMPLATES_DETAILS + id,
      "",
      "get",
      null,
      true,
      false,
      ADMIN_URL,
    )
    if (response.data) {
      setEmailTitle(response.data?.title)
      setSubject(response.data?.subject)
      setGHtml(response.data?.content)
    }
  }

  /******************* 
  @Purpose : Used for page title change
  @Parameter : e
  @Author : INIC
  ******************/
  const handleTrackChange = (e, currentTrack, type) => {
    let maxChar = 70
    currentTrack.current.textContent = `Maximum ${
      maxChar - e.target.value.length
    } characters is suitable`
    if (type === "title") {
      setEmailTitle(e.target.value.replace(/  +/g, " "))
      errors = Object.assign(errors, { emailTitle: "" })
    }
    if (type === "subject") {
      setSubject(e.target.value.replace(/  +/g, " "))
      errors = Object.assign(errors, { subject: "" })
    }
    setErrors(errors)
  }

  /******************* 
  @Purpose : Used for preview
  @Parameter : content
  @Author : INIC
  ******************/
  const getpreview = (content) => {
    setGHtml(content)
  }

  /******************* 
  @Purpose : Used for handle add admin process
  @Parameter : {}
  @Author : INIC
  ******************/
  const updateEmailTemplatesHandler = async () => {
    try {
      const body = {
        title: emailTitle,
        subject: subject,
        content: gjsHtml,
      }
      if (validateForm() === true) {
        setLoading(true)
        const response = await props.callApi(
          API.UPDATE_EMAIL_TEMPLATES + staticId,
          body,
          "patch",
          "",
          true,
          false,
          ADMIN_URL,
        )

        showMessageNotification(response?.extra_meta?.message + ".", "success")
        props.history.push("/email-templates")
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  /******************* 
  @Purpose : Used for Validate Form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    var titleFormat = /^[a-zA-Z0-9 ]{5,70}$/
    let tempErrors = { emailTitle: "", gjsHtml: "", subject: "" }
    let isFormValid = true

    //for page title
    if (!emailTitle) {
      tempErrors.emailTitle = ErrorMessages.PROVIDE_EMAIL_TITLE
    } else if (!titleFormat.test(emailTitle)) {
      tempErrors.emailTitle = ErrorMessages.PROVIDE_VALID_EMAIL_TITLE
    } else {
      tempErrors.emailTitle = ""
    }

    if (!subject) {
      tempErrors.subject = ErrorMessages.PROVIDE_EMAIL_SUBJECT
    } else if (!titleFormat.test(subject)) {
      tempErrors.subject = ErrorMessages.PROVIDE_VALID_EMAIL_SUBJECT
    } else {
      tempErrors.subject = ""
    }

    //for page content
    if (gjsHtml.length < 50) {
      tempErrors.gjsHtml = ErrorMessages.PROVIDE_AT_LEAST_FIFTY_CHAR
    } else {
      tempErrors.gjsHtml = ""
    }

    if (
      tempErrors.emailTitle !== "" ||
      tempErrors.gjsHtml !== "" ||
      tempErrors.subject !== ""
    ) {
      isFormValid = false
    }

    setErrors(tempErrors)
    return isFormValid
  }

  const onChangeCkEditor = (evt) => {
    var html = evt.editor.getData()
    setGHtml(html)
  }
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <nav aria-label="breadcrumb">
                <h3 className="navHeading">Manage Email Templates</h3>
                <ol className="breadcrumb">
                  <li className="content-header-title disabled">Navigation</li>
                  <li className="content-header-title">
                    <span
                      onClick={() => props.history.push("/email-templates")}
                    >
                      Email Templates
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link onClick={() => props.history.push("/dashboard")}>
                      <i className="bx bx-home-alt"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Email Templates
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex align-items-center justify-content-end mb-2 flex-wrap">
              <span className="author d-lg-none d-flex">
                {lang("ContentManagement.CMS.Author")}
              </span>
            </div>
            <div className="blog-content-block">
              <div className="card card-media">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-12">
                    <div className="form-group mb-md-5 mb-3">
                      <label htmlFor="pageTitle">
                        Email Title<sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="pageTitle"
                        name="pageTitle"
                        placeholder="Email Template"
                        value={emailTitle}
                        maxLength="70"
                        onChange={(e) =>
                          handleTrackChange(e, emailTitleTrackChanges, "title")
                        }
                      />
                      <small
                        id="pageTitle"
                        className="form-text"
                        ref={emailTitleTrackChanges}
                      >
                        Maximum {70 - emailTitle.length} characters is suitable
                      </small>
                      <span className="text-danger d-block">
                        {errors.emailTitle}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-md-5 mb-3">
                      <label htmlFor="pageTitle">
                        Email Subject<sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="pageTitle"
                        name="pageTitle"
                        placeholder="Email Template Subject"
                        value={subject}
                        maxLength="70"
                        onChange={(e) =>
                          handleTrackChange(
                            e,
                            emailSubjectTrackChanges,
                            "subject",
                          )
                        }
                      />
                      <small
                        id="pageTitle"
                        className="form-text"
                        ref={emailSubjectTrackChanges}
                      >
                        Maximum {70 - subject.length} characters is suitable
                      </small>
                      <span className="text-danger d-block">
                        {errors.subject}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="blog-content">
                  <div className="img-drag-section">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-0">
                          <label htmlFor="caption">
                            {lang("ContentManagement.CMS.content")}
                            <sup className="text-danger">*</sup>
                          </label>

                          {/* <StaticEditorEdit
                            className="form-control mx-auto"
                            editContent={gjsHtml}
                            ref={editorRef}
                            getPreview={getpreview}
                          /> */}
                          <CKEditor
                            activeClass="p10"
                            content={gjsHtml}
                            config={ckEditorConfig}
                            events={{
                              // blur: this.onBlur,
                              afterPaste: (data) => {
                                onChangeCkEditor(data)
                              },
                              change: (data) => {
                                onChangeCkEditor(data)
                              },
                            }}
                          />
                          <span className="text-danger d-block">
                            {errors.gjsHtml}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-3">
                  <div className="form-group">
                    <div className="button-group-container">
                      {
                        <React.Fragment>
                          <button
                            onClick={updateEmailTemplatesHandler}
                            className="btn btn-primary mr-3 mb-sm-0 mb-2"
                          >
                            <span> {loading ? "Updating" : "Update"}</span>
                          </button>
                        </React.Fragment>
                      }
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          props.history.push("/email-templates")
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2020 © IndiaNIC</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => {
  return {
    emailId: state.admin.editEmailId,
    language: state.admin.language,
    EmailTemplateAccess:
      // state.admin.adminData.staticRolePermission.emailTemplateAccess,
      { edit: true, delete: true, create: true },
  }
}
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(
  withRouter(EditEmailTemplate),
)
