import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { callApi } from "../../api" // Used for api call
import { Link } from "react-router-dom"
import { isEmpty, times } from "lodash"
import { connect } from "react-redux"
import errorMessages from "../../utils/ErrorMessages"
import API from "../../api/Routes"
import "react-datepicker/dist/react-datepicker.css"
import Layout from "../../components/Layout/Layout"
import {
  setFavIcon,
  showMessageNotification,
  validateHotelname,
} from "../../utils/Functions" //Utility functions
import { ADMIN_URL } from "../../config"

/******************* 
@Purpose : Used for user management (add user) page
@Parameter : props
@Author : INIC
******************/
function DetailHotel(props) {
  const [lang] = useTranslation("language")
  let [loading, setLoading] = useState(false)
  const [renderEmail, setRenderEmail] = useState(1)
  const [hotelName, setHotelName] = useState("")
  const [hotelEmailList, setHotelEmailList] = useState([])
  const [, setIsFormValid] = useState("")
  let [errors, setErrors] = useState("")
  let hotelId = props.match.params.slug

  useEffect(() => {
    getSingleHotel(hotelId)
    setFavIcon(document.getElementById("favicon"))
  }, [])

  /******************* 
  @Purpose : Used for validate form field
  @Parameter : {}
  @Author : INIC
  ******************/
  const getSingleHotel = async (hotelid) => {
    const response = await props.callApi(
      API.HOTEL_DETAIL + hotelId,
      "",
      "get",
      null,
      true,
      false,
      ADMIN_URL,
    )
    if (response.data) {
      setHotelName(response.data?.hotelName)
      setRenderEmail(response.data?.hotelUserEmail?.length)
      setHotelEmailList(response.data?.hotelUserEmail)
    }
  }

  /******************* 
  @Purpose : Used for validate form field
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let errors = { hotelName: "", email: "" }
    let isFormValid = true

    if (isEmpty(hotelName)) {
      errors.hotelName = errorMessages.PROVIDE_HOTEL_NAME
    } else if (!validateHotelname(hotelName)) {
      errors.hotelName =
        errorMessages.PROVIDE_VALID_HOTEL_NAME +
        "(Max 30 without special character)"
    } else {
      errors.hotelName = ""
    }

    if (errors.hotelName !== "") isFormValid = false
    else isFormValid = true

    setErrors(errors)
    setIsFormValid(isFormValid)
    return isFormValid
  }

  /******************* 
  @Purpose : Used for handle add admin process
  @Parameter : {}
  @Author : INIC
  ******************/
  const updateHotelHandler = async (e) => {
    e.preventDefault()
    if (validateForm()) {
      let body = {
        id: hotelId,
        name: hotelName,
      }
      setLoading(true)
      try {
        const response = await props.callApi(
          API.HOTEL_UPDATE,
          body,
          "put",
          "",
          true,
          false,
          ADMIN_URL,
        )

        showMessageNotification(response?.extra_meta?.message + ".", "success")
        props.history.push("/hotel-management")
        setLoading(false)
      } catch (error) {
        setLoading(false)
        throw error
      }
    }
  }

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <nav aria-label="breadcrumb">
                  <h3 className="navHeading">Manage Hotels</h3>
                  <ol className="breadcrumb">
                    <li className="content-header-title disabled">
                      Navigation
                    </li>
                    <li className="content-header-title">
                      <span
                        onClick={() => props.history.push("/hotel-management")}
                      >
                        Hotels
                      </span>
                    </li>
                    <li className="breadcrumb-item">
                      <span onClick={() => props.history.push("/dashboard")}>
                        <i className="bx bx-home-alt"></i>
                      </span>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hotel Detail
                    </li>
                  </ol>
                </nav>
                <div className="btn-blocks mb-md-0 mb-2">
                  <button
                    className="btn btn-primary glow-primary mr-3"
                    onClick={(e) => updateHotelHandler(e)}
                  >
                    <em className="bx bx-revision mr-2" />
                    {loading ? "Updating" : "Update"}
                  </button>
                </div>
              </div>
              <div className="card profile-card mb-5">
                <div className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-8 mt-3">
                        <div className="user-title-info user-details">
                          <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                            <label className="mb-0" htmlFor="firstName">
                              {lang("Hotelmanagement.hotelname")}
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="w-100">
                              <input
                                type="text"
                                className="form-control"
                                id="hotelName"
                                name="hotelName"
                                placeholder={lang("Hotelmanagement.hotelname")}
                                value={hotelName}
                                onChange={(e) => {
                                  setHotelName(
                                    e.target.value.replace(/  +/g, " "),
                                  )
                                  errors = Object.assign(errors, {
                                    hotelName: "",
                                  })
                                  setErrors(errors)
                                }}
                              />
                              <span className="text-danger d-block">
                                {errors.hotelName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {times(renderEmail, function (i) {
                        return (
                          <>
                            <div className="col-md-8 mt-3">
                              <div className="user-title-info user-details">
                                <div className="form-group d-flex align-items-center mb-md-4 mb-3">
                                  <label className="mb-0" htmlFor="hotelName">
                                    {lang("Hotelmanagement.hotelsystemadmin")}
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="w-100">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`hotelEmail` + i}
                                      name="hotelEmail"
                                      placeholder={lang(
                                        "Hotelmanagement.hotelsystemadmin",
                                      )}
                                      value={hotelEmailList[i]?.emailId}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2020 © IndiaNIC</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  )
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
})
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(DetailHotel)
