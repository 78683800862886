const INTIAL_STATE = {
    files: [],
    newfile:{},
    fileproceed:{},
    openmodel:false
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case "OPEN_MODEL":
            return {
                ...state,
                openmodel: true
            };
            case "CLOSE_MODEL":
            return {
                ...state,
                openmodel: false
            };
        case "NEW_FILE_UPLOAD_REQUEST":
            return {
                ...state,
                newfile: action.payload.file
            };
            case "FILE_UPLOAD_PROCEED":
                return {
                    ...state,
                    fileproceed: action.payload.file
                };
        case "FILE_UPLOAD":
            return {
                ...state,
                files: action.payload.files
            };
        case "FILE_UPLOAD_SUCCESS":
            return Object.assign({}, state, {
                files: action.payload.files
            });
        case "FILE_UPLOAD_FAIL":
            return Object.assign({}, state, {
                files: action.payload.files
            });
        case "FILE_UPLOAD_CANCEL":
            return Object.assign({}, state, {
                files: action.payload.files
            });
        case "FILE_UPLOAD_CANCEL_ALL":
            return {
                ...state,
                files: [],
                newfile:{},
                fileproceed:{}
            };
        case "FILE_UPLOAD_DONE":
            return {
                ...state,
                files: [],
                newfile:{},
                fileproceed:{}
            };
        default:
            return state;
    }
};
